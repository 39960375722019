<template>


  <div class="h-screen w-screen rounded ">

    <!-- sidebar  -->
    <div class="  h-full w-full   mx-auto  bg-white rounded flex shadow-2xl ">
      <!-- side menu -->
      <div v-if="1+1==2" class="w-50   rounded-bl-lg ">
        <!-- Sidebar -->
        <div
          class=" flex flex-col top-14 left-0 w-14 hover:w-64 md:w-64 bg-green-900 dark:bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar rounded-bl-lg">
          <div class="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
            <ul class="flex flex-col py-4 space-y-1">

              <li >
                <a href="/dashboard/home"
                  class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-800 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-green-500 dark:hover:border-gray-800 pr-6">
                  <span class="inline-flex justify-center items-center ml-4">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                      </path>
                    </svg>
                  </span>
                  <span class="ml-2 text-sm tracking-wide truncate">Tableau de bord</span>
                </a>
              </li>
        
              <li>
                <a href="/dashboard/studentlist"
                  class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-800 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6">
                  <span class="inline-flex justify-center items-center ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
</svg>

                  </span>
                  <span class="ml-2 text-sm tracking-wide truncate">Liste de vos inscrits.tes</span>
                </a>
              </li>
              <li>
                <button  @click="showUserForm = !showUserForm"
                  class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-800 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6">
                  <span class="inline-flex justify-center items-center ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

                  </span>
                  <span class="ml-2 text-sm tracking-wide truncate">Ajouter un.e apprenant.e</span>
                 
                </button>
              </li>
     
              <!-- <li>
                <router-link to="/user-profile"
                  class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-800 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6">
                  <span class="inline-flex justify-center items-center ml-4">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                    </svg>
                  </span>
                  <span class="ml-2 text-sm tracking-wide truncate">Profile</span>
                  </router-link>
              </li> -->
              
            
              <li>
                <router-link to="/contes"
                  class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-800 dark:hover:bg-gray-600 text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6">
                  <span class="inline-flex justify-center items-center ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

                  </span>
                  <span class="ml-2 text-sm tracking-wide truncate">Quitter le tableau de bord</span>
                </router-link>
              </li>
            </ul>
            <p class="mb-14 px-5 py-3 hidden md:block text-center text-xs">Copyright @2021</p>
          </div>
        </div>
        <!-- ./Sidebar -->
      </div>
      <!-- view port -->
      <div class="w-full h-full ">
<div class="flex justify-between items-center shadow-xl rounded-lg h-auto mx-3 w-max-full   ">
        <div class="flex items-center justify-between  w-full p-2">
              <div class="flex items-center justify-between">
                <div class="text-gray-600 text-xs">Apprenant.e(s)<br>connecté.e(s)</div>
                <div class="h-100 border-l mx-4"></div>
                <div class="flex flex-nowrap -space-x-3">
                  <div v-for="(student,index) in students.students" :key="index" class="h-9 w-9 ">
                    <img class="object-cover w-full h-full rounded-full"
                      src="https://ui-avatars.com/api/?background=random">
                      <!-- <div   class=" uppercase w-full h-full rounded-full">{{ student.firstname.charAt(0) }}</div> -->

                  </div>
                  <!-- <div class="h-9 w-9">
                    <img class="object-cover w-full h-full rounded-full"
                      src="https://ui-avatars.com/api/?background=random">
                  </div> -->
                </div>
              </div>
              <AddChild :showUserForm="showUserForm">
                <form @submit.prevent="studentRegister()">
                  <div class=" w-full space-y-4 p-10 bg-white  z-10">
                    <h1 class="text-red-400">{{message}}</h1>
                    <div class="grid  gap-8 grid-cols-1">
                      <div class="flex flex-col ">
                        <div class="mt-2">
                          <div class="form">
                            
                       
                            <div class="md:flex flex-row md:space-x-4 w-full text-xs">
                              <div class="mb-2 space-y-1 w-full text-xs">
                                <label class="font-semibold text-gray-600 py-1 text-left block">Nom </label>
                                <input v-model="studentData.lastname" placeholder=""
                                  class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                                  required="required" type="text" name="integration[shop_name]"
                                  id="integration_shop_name">
                                <p class="text-red text-xs hidden">Please fill out this field.</p>
                              </div>
                              <div class="mb-2 space-y-1 w-full text-xs">
                                <label class="font-semibold text-gray-600 py-1 text-left block">Prénom </label>
                                <input v-model="studentData.firstname" placeholder="aziz"
                                  class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                                  required="required" type="text" name="integration[shop_name]"
                                  id="integration_shop_name">
                                <p class="text-red text-xs hidden">Please fill out this field.</p>
                              </div>
                            </div>
                            <!-- <div class="mb-2 space-y-1 w-full text-xs">
                              <label class=" font-semibold text-gray-600 py-2 text-left block">Email</label>
                              <div class="flex flex-wrap items-stretch w-full mb-4 relative">
                                <div class="flex">
                                  <span
                                    class="flex items-center leading-normal bg-green-800   border-1 rounded-r-none border border-r-0  px-3 whitespace-no-wrap text-grey-dark text-sm w-12 h-10 justify-center items-center  text-xl rounded-lg text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                      stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                  </span>
                                </div>
                                <input v-model="studentData.email" type="text"
                                  class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border border-l-0 h-10 border-grey-light rounded-lg rounded-l-none px-3 relative focus:border-blue focus:shadow"
                                  placeholder="hello@gmail">
                              </div>
                            </div> -->
                            <div class="mb-2 space-y-1 w-full text-xs">
                              <label class=" font-semibold text-gray-600 py-2 text-left block">Mot de passe</label>
                              <div class="flex flex-wrap items-stretch w-full mb-4 relative">
                                <div class="flex">
                                  <span
                                    class="flex items-center leading-normal bg-green-800   border-1 rounded-r-none border border-r-0  px-3 whitespace-no-wrap text-grey-dark text-sm w-12 h-10 justify-center items-center  text-xl rounded-lg text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                      stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                                    </svg>

                                  </span>
                                </div>
                                <input v-model="studentData.password" type="text"
                                  class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border border-l-0 h-10 border-grey-light rounded-lg rounded-l-none px-3 relative focus:border-blue focus:shadow"
                                  placeholder="*************">
                              </div>
                            </div>
                            <div class="md:flex md:flex-row md:space-x-4 w-full text-xs">
                              <div class="w-full flex flex-col mb-2">
                                <label class="font-semibold text-gray-600 py-2 text-left block">École</label>
                                <input v-model="studentData.establishment" placeholder="Collège Saint Viateur d'Abidjan"
                                  class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                                  type="text" name="integration[street_address]" id="integration_street_address">
                              </div>
                              <div class="w-full flex flex-col mb-3">
                                <label class="font-semibold text-gray-600 py-2 text-left block">Niveau académique
                                </label>
                                <select 
                                  class="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full "
                                  required="required" name="integration[city_id]" id="integration_city_id">
                                  <option value="">Maternelle/Primaire</option>
                                  <option value="">Secondaire</option>
                                  <option value="">Supérieur</option>
                                </select>
                                <p class="text-sm text-red-500 hidden mt-3" id="error">Please fill out this field.</p>
                              </div>
                            </div>
                            <!-- <div class="flex-auto w-full mb-1 text-xs space-y-2">
                              <label class="font-semibold text-gray-600 py-2 text-left block">Informations
                                supplémetaire</label>
                              <textarea required="" name="message" id=""
                                class="w-full min-h-[100px] max-h-[300px] h-28 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg  py-4 px-4"
                                placeholder="Enter your comapny info" spellcheck="false"></textarea>
                              <p class="text-xs text-gray-400 text-left my-3">You inserted 0 characters</p>
                            </div> -->
                            <div class="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                              <button @click="close()"
                                class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-lg hover:shadow-lg hover:bg-gray-100">
                                Retour </button>
                              <button
                                class="mb-2 md:mb-0 bg-green-700 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-lg hover:shadow-lg hover:bg-green-500">Valider</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              </AddChild>
              
              <div class="flex items-center gap-x-2">
            
                <button type="button" @click="showUserForm = !showUserForm"
                  class="inline-flex items-center justify-center h-9 px-5 rounded-xl bg-gray-900 text-gray-300 hover:text-white text-sm font-semibold transition">
                  Ajouter un Apprenant.e
                </button>
              </div>
            </div>
            <hr class="my-10">
      </div>
      <div class="overflow-y-auto h-auto">
        <router-view>

        </router-view>
      </div>
      </div>

      
    </div>


  </div>

</template>

<script setup>
import { ref,onMounted } from 'vue';
import AddChild from '../components/dashboard/addChild.vue';
// import LoadingPage from '../../components/LoadingPage.vue';
import axios from 'axios'

import { inject } from 'vue'
import { useStore } from 'vuex'
import { setTimeout } from 'core-js';
let swal = inject('$swal');
const showUserForm = ref(false);
const alertMessage = ref('enfant ajouter avec succes');
const showmessage = ref(false);
const store = useStore();
let message = ref('');
const loading = ref(true);
const students = ref([]);
const studentData = ref({
  lastname: '',
  firstname: '',
  classroom: 'cm',
  establishment: '',
  // email: '',
  password: ''

});

async function getUserData() {
  let response = await axios.get('/api/v1/parent/students');
  students.value = response.data
}

onMounted(() => {
  showLoading();
  getUserData();
  })


function showLoading(){
  loading.value == true;
  setTimeout(() => {
    loading.value = false;
  }, 2000);
} 

 function studentRegister() {
  axios.post('/api/v1/student/register',studentData.value)
  .then((response)=>{
    console.log(response.data);
    message.value = 'Enfant créer avec succès';
    showUserForm.value = false;
  })
  .catch((err)=>{
    console.log('esdfggg',err);
    message.value = err.response.data.message;
    setTimeout(() => {
    message.value = ''
  }, 3000);
    
  })

}

function showModal() {
  showUserForm.value = !showUserForm.value;
}
function close(){
  showUserForm.value = !showUserForm.value;
}
function showMessage() {
  if (alertMessage.value.length > 0) {
    showmessage.value = !showmessage.value;
    setTimeout(() => {
      showmessage.value = false;
    }, 1000);
    showModal();
    const unwrapped = JSON.parse(JSON.stringify(childData));
    console.log(unwrapped);

  }
}
function showAlert() {
  swal.fire(
    {
      position: 'top-end',
      title: 'user is created',
      padding: '0.5%',
      timer: 1500
    }
  )
}



</script>

<style  scoped>



</style>