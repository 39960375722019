<script>
import axios from "axios"
export default {
	data() {
		return {
			student:'',
			id:null,
			student:'',
			studentTales : []
		}
	},
	methods:{
		// historique des contes lus
		async getStudentData(){
			let response = await axios.get(`/parent/students/historiques`);
			// console.log(response.data);
		},

	 async getStudentTale(){
		let response = await axios.get(`/api/v1/parent/student/${this.$route.params.id}/historiques`);
		this.studentTales  = response.data.StudentHistoricalAll;
		console.log(response.data );
	 },
	 async getStudentInfo(){
		let response = await axios.get(`/api/v1/parent/student/${this.$route.params.id}`);
		console.log(response.data);
		this.student = response.data;
	 },

	 async getStudentHistorisque(){
		let response = await axios.get(`/api/v1/parent/students/historiques`);
		console.log(response.data);
	 },
	},
	mounted() {
		this.id = this.$route.params.id;
		this.getStudentData();
		this.getStudentTale();
		this.getStudentInfo();
		this.getStudentHistorisque()
	},
}
</script>

<template>
	<body class="   flex items-center justify-center m-4  ">

<div class="bg-white p-8 rounded shadow-md w-96">
	<!-- User Information -->
	<div class="text-center mb-4">
		<img src="https://cdn3.iconfinder.com/data/icons/vector-icons-6/96/256-512.png" alt="User Photo" class="w-20 h-20 rounded-full mx-auto mb-2">
		<h2 class="text-xl font-semibold">{{ student.student?.firstname }}</h2>
		<p class="text-gray-800 ">{{ student.student?.lastname }}</p>
		<p class="text-gray-800 ">Email:{{student.student?.user.email}}</p>
		<p class="text-gray-800 ">{{ student.student?.classroom}}</p>
		<p class="text-gray-800 ">Ecole: {{ student.student?.establishment  }}</p>
	</div>

	<!-- List of Videos -->
	<div class="flex-shrink-0 flex-grow overflow-y-auto   h-64">
		<h3 class="text-lg font-semibold mb-2">Contes lue(s)</h3>
		<div class="">
			<!-- Video Card 1 -->
			<div v-for="(tale,index) in studentTales" class="bg-gray-200 p-4 rounded-md flex m-2">
				<!-- cover -->
				<div class="h-full w-20  mr-3">
					<img :src="tale.tale.imageBackground" alt="">
				</div>
				<div>
					<h4 class="text-lg font-semibold mb-2">{{tale.tale.title}}</h4>
				<p class="text-gray-500">Date: January 1, 2023</p>
				<p class="text-gray-500">Type: Educational</p>
				</div>
			
			</div>
		</div>
	</div>
</div>

	</body>
	</template>