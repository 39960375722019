
<script setup>
import LoadingPage from '../components/LoadingPage.vue';
import {useRoute} from 'vue-router'
import {ref,onMounted, computed} from 'vue'
import {useStore} from 'vuex'
import axios from 'axios';
let loading = ref(true);
const tales = ref([]);

const conteurs =ref([]);
let router =  useRoute();
let id = ref(null);

const showMore = ref(false)

onMounted(() => {
  showLoading();
  id = router.params.id;
  getStoryTeller();
  getStoryTallerTales();
  })

  computed(()=>{

  })

async function getStoryTeller(){
  await axios.get(`/api/v1/storyteller/${id}`)
  .then((response)=>{
    console.log(response.data);
    conteurs.value =  response.data.storyteller;
  })
} 

async function getStoryTallerTales(){
  let response = await axios.get(`/api/v1/storyteller/tales`)
  console.log(response.data.storyteller);
  tales.value = response.data.storyteller;
}


function showLoading(){
  loading.value == true;
  setTimeout(() => {
    loading.value = false;
  }, 2000);
}
</script>

<template>
  <!-- component -->

<div>
<div v-if="loading"> 
  <LoadingPage /> 
  </div>
<div v-else class="w-full h-full  bg-gray-100">
    <div class="container mx-auto my-5 p-5">
      <div class="md:flex no-wrap md:-mx-2 ">
        <!-- Left Side -->
        <div class="w-full md:w-3/12 md:mx-2">
          <!-- Profile Card -->
          <div class="bg-white p-3 border-t-4 border-green-400 flex flex-col items-center justify-center">
            <div class=" rounded-full bg-red-600 h-48 w-48 flex items-center  justify-center">
              <img class="h-full w-full  rounded-full" src="https://res.cloudinary.com/dh8xobjon/image/upload/v1710362887/xxbeq0kemxwcesyqaoor.jpg" alt="profil">
            </div>
            <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{{ conteurs.storyTellersName
 }}</h1>
            <h3 class=" font-lg text-semibold leading-6">Biographie</h3>
            <p class="text-sm  text-base text-gray-500 hover:text-gray-600 w-full  " :class="{ 'truncate': !showMore }">{{ conteurs.fieldOfActivity }}</p>
            <button @click="showMore = !showMore" class="text-green-500 hover:text-green-700">
              {{ showMore ? 'Voir moins' : 'Voir plus...' }}
            </button>
            <ul
              class="w-full bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
              <li class="flex items-center py-3">
                <span>Status</span>
                <span class="ml-auto"><span class="bg-green-500 py-1 px-2 rounded text-white text-sm">Actif.ve</span></span>
              </li>
              <li class="flex items-center py-3">
                <span>Membre depuis</span>
                <span class="ml-auto">10 février 2023</span>
              </li>
              <li class="flex items-center py-3">
                <span>Abonnés</span>
                <span class="ml-auto">30</span>
              </li>
            </ul>
          </div>
          <!-- End of profile card -->
          <div class="my-4"></div>
          <!-- Friends card -->
          <div class="bg-white p-3 hover:shadow ">
            <div class="flex items-center space-x-3 font-semibold text-gray-900 text-xl leading-8">
              <span class="text-green-500">
                <svg class="h-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </span>
              <span> Profils Similaires</span>
            </div>
            <div class="grid grid-cols-3">
              <div v-for="i in 4" class="text-center my-2">
                <img class="h-16 w-16 rounded-full mx-auto"
                  src="https://cdn.australianageingagenda.com.au/wp-content/uploads/2015/06/28085920/Phil-Beckett-2-e1435107243361.jpg"
                  alt="">
                <a href="#" class="text-main-color">Kojstantin</a>
              </div>
            </div>
          </div>
          <!-- End of friends card -->
        </div>
        <!-- Right Side -->
        <div class="w-full md:w-9/12 mx-2 h-auto  mt-2">
          <!-- Profile tab -->
          <!-- About Section -->
          <div class="bg-white p-3 shadow-sm rounded-sm">
            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span clas="text-green-500">
                <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </span>
              <span class="tracking-wide">Informations sur le conteur</span>
            </div>
            <div class="text-gray-700">
              <div class="flex   text-sm text-left">
                <div class="grid grid-cols-1">
                  <div class="px-4 py-2 font-semibold">Nom et Prénoms:</div>
                  <div class="px-4 py-2">{{conteurs.storyTellersName}}</div>
                </div>
                <!-- <div class="grid grid-cols-1">
                  <div class="px-4 py-2 font-semibold"> :</div>
                  <div class="px-4 py-2">Doe</div>
                </div> -->
           
                <!-- <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Contact</div>
                  <div class="px-4 py-2">+{{conteurs.phone}}</div>
                </div> -->
                <!-- <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Current Address</div>
                  <div class="px-4 py-2">Beech Creek, PA, Pennsylvania</div>
                </div> -->
                <div class="grid grid-cols-1">
                  <div class="px-4 py-2 font-semibold">Pays :</div>
                  <div class="px-4 py-2">Côte d’Ivoire</div>
                </div>
                <div class="grid grid-cols-1 ">
                  <div class="px-4 py-2 font-semibold">E-mail :</div>
                  <div class="px-4 py-2">
                    <a class="text-blue-800" href="mailto:jane@example.com">corinne.lartetlamarmaille@gmail.com</a>
                  </div>
                </div>
                <!-- <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Date de naissance</div>
                  <div class="px-4 py-2">Feb 06, 1998</div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- End of about section -->

          <div class=" mt-2"></div>
          <div class="bg-white p-3 shadow-sm rounded-sm ">
            <div class="flex items-center justify-start mb-2">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                class="w-6 h-6 ml-7 text-gray-500">
                <path
                  d="M4.5 4.5a3 3 0 00-3 3v9a3 3 0 003 3h8.25a3 3 0 003-3v-9a3 3 0 00-3-3H4.5zM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06z" />
              </svg> -->
              <h4 class="text-left text-xl font-bold p-3">Liste de ses contes</h4>
            </div>
            <div class=" mx-auto max-w-2xl  px-4  sm:px-6 lg:max-w-7xl lg:px-8">


              <div class=" grid grid-cols-1 gap-y-4 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                <a v-for="(tale,ndex) in conteurs.tales" :key="index"  class="group">
                  <div
                    class="h-48 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-t-lg  bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                    <img :src="tale.imageBackground" alt="text"
                      class="h-full w-full object-cover object-center group-hover:opacity-75" />
                      
                  </div>
                  <div class="w-auto h-auto">
                    <div class="flex-1 h-full">
                      <router-link :to="{ name: 'Detail', params: { id: tale._id } }" 
                        class="flex items-center justify-center flex-1 h-full p-2 bg-green-500 text-white shadow rounded-b-lg">
                        <div class="relative">
                          <span>Voir ce conte</span>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <button
              class=" flex items-center justify-center  w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">
            
              Voir plus de contes
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4 ml-2">
  <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
</svg>

              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  
</template>

<style>
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10; /* Nombre de lignes à afficher avant troncature */
    -webkit-box-orient: vertical;
  }
</style>

  