<template>
  <div class=" w-full ">

    <!-- <div v-if="notes > 0" class="flex bg-green-100 ml-2 md:w-1/3 rounded-lg p-4 mb-2 text-sm text-green-700 "
      role="alert">
      <div class="flex">
        <svg class="w-5 h-5 inline mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </svg>
        <div>
          <span class="font-medium">Exellent!</span> votre note est {{ notes }} /10.
        </div>
      </div>
    </div> -->
    <!-- <Modal class="" @close="toggleModal" :modalActive="modalActive"></Modal> -->
    <!-- Main modal -->
    <teleport to="body">
      <modal :show="showModal" @close="showModal = false">
        <div class="flex flex-col items-center justify-center">
          <div class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-16 h-16 text-green-800">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
            </svg>
          </div>
          <h3 class="text-sm text-gray-500">Bravo ! Vous avez terminé </h3>
          <h4><span class="text-red-800 font-bold">{{ contes.title }}</span></h4>

        </div>
        <form class="w-full">
          <div class="bg-white px-2 py-2 rounded-xl   ">
            <div class="space-y-4">
              <p class="text-sm text-left text-gray-500">Veillez à présent donner votre avis dans les champs  ci-dessous,
                 pour évaluer votre niveau de compréhension.</p>
              <div>
                <h6 class="text-green-800 font-semibold text-sm pb-1">Bon à savoir</h6>
                <div>
                  <div class="   mb-4 ">
                    <span class="text-sm text-gray-800 mr-2">Pour avoir regardé, écouté ou lu ce conte, vous avez fait</span>
                    <span class="text-sm text-red-500 font-semibold">25% <span class="text-sm text-gray-500">de
                        progression</span></span>
                  </div>
                </div>
              </div>
              <div class="pt-1">
                <ul>
                  <!-- <li v-for="item in responses1" :key="item.id"> -->
                  <li>
                    <h6 class="block mb-1 text-gray-600 text-sm text-green-900 font-bold"> {{ question1 }}
                    </h6>
                    <ul>
                      <!-- <li v-for="childItem in item.responses" :key="childItem.id"> -->
                      <li>
                        <div class="flex items-center ">
                          <input v-model="result1" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response1_1 }} </label>
                        </div>
                        <div class="flex items-center ">
                          <input v-model="result2" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response1_2 }} </label>
                        </div>
                        <div class="flex items-center ">
                          <input v-model="result3" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response1_3 }} </label>
                        </div>

                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="pt-1">
                <ul>
                  <!-- <li v-for="item in responses1" :key="item.id"> -->
                  <li>
                    <h6 class="block mb-1 text-gray-600 text-sm text-green-900 font-bold"> {{ question2 }}
                    </h6>
                    <ul>
                      <!-- <li v-for="childItem in item.responses" :key="childItem.id"> -->
                      <li>
                        <div class="flex items-center ">
                          <input v-model="result4" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response2_1 }} </label>
                        </div>
                        <div class="flex items-center ">
                          <input v-model="result5" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response2_2 }} </label>
                        </div>
                        <div class="flex items-center ">
                          <input v-model="result6" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response2_3 }} </label>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>

              </div>
              <div class="pt-1">
                <ul>
                  <!-- <li v-for="item in responses1" :key="item.id"> -->
                  <li>
                    <h6 class="block mb-1 text-gray-600 text-sm text-green-900 font-bold"> {{ question3 }}
                    </h6>
                    <ul>
                      <li>
                        <div class="flex items-center ">
                          <input id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response3_1 }} </label>
                        </div>
                        <div class="flex items-center ">
                          <input v-model="result8" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response3_2 }} </label>
                        </div>
                        <div class="flex items-center ">
                          <input v-model="result9" id="vue-checkbox-list" type="checkbox" value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                          <label for="vue-checkbox-list"
                            class="w-full py-1 ml-2 text-sm  text-gray-900 dark:text-gray-300">
                            {{ response3_3 }} </label>
                        </div>

                      </li>
                    </ul>
                  </li>
                </ul>

              </div>
              <div @click="stop" class="w-full flex items-center justify-between mt-2 pt-3">
                <button @click="closeModal()" type="button"
                  class="  w-auto  text-gray-900 bg-gradient-to-r from-green-200 via-lime-400 to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Soumettre maintenant</button>

                <button type="button"
                  class="  overflow-x-auto   text-gray-900 bg-gradient-to-r from-gray-200 via-lime-400 to-gray-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Soumettre plus-tard</button>
               
              </div>
            </div>
          </div>
        </form>
      </modal>
    </teleport>


    <!-- result modal -->
    <teleport to="body">
      <NoteModal :show="showModal2" @close="showModal2 = false">
        <div class="flex flex-col items-center justify-center">
          <div class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-16 h-16 text-green-800">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
            </svg>
          </div>
          <h3 class="text-sm text-gray-500">Bravo ! Vous avez terminé </h3>
          <h4>
            Merci d'avoir regardé/écouté/lu le conte {{ contes.title }}
            Votre note est : <span class="text-2xl text-red-800">{{ notes }}</span> / 10
          </h4>

        
          

        </div>
        <div class="w-full">
          <div  class="w-full flex items-center justify-between mt-2 pt-3">
              
            <!-- <router-link  class="border-2  w-full border-gree-600 rounded-lg px-3 py-1 text-green-400 cursor-pointer hover:bg-green-600 hover:text-white" :to="{ name: 'ResolutionPage', params: { id: contes.id } } " > Ajouter ma résolution 
              <span>Prenez votre resolution maintenant</span> 
            </router-link> -->
            <router-link 
                class="w-full  text-green-900 bg-gradient-to-r from-gray-200 via-lime-400 to-green-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                :to="{ name: 'ResolutionPage', params: { id: this.id } }">
                <span>Ajouter une résolution</span>
            </router-link>


                

                <button type="button" @click="showModal2 = !showModal2"
                  class=" w-full  text-gray-900 bg-gradient-to-r from-gray-200 via-lime-400 to-gray-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">plus-tard</button>
               
              </div>
        </div>
      </NoteModal>
    </teleport>

    <div class="h-full w-full  md:p-6 p-1">
      <!-- <button @click="showModal2 = !showModal2" class="bg-red-600 p-2 rounded">Test modal2</button> -->
      <!-- route -->
      <div class="w-full h-12 bg- flex items-center border-1 ">
        <div class="bg-white cursor-pointer border w-10 h-10 rounded-lg ml-4 flex items-center justify-center mr-2">
          <router-link to="/contes">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>
          </router-link>
        </div>
        <h4 class="text-sm mr-1">{{ contes.typeTale }}</h4>
        <span class="flex space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
          </svg>
          <span class="text-gray-500 font-bold"> {{ contes.title }}</span>
        </span>
      </div>
      <div class="w-full h-full   flex flex-col items-center ">
        <!-- video content -->
        <div class="md:flex w-full">
          <!-- video -->
          <div class=" relative   h-auto w-full ">
            <div class=" w-full h-2/3  ">
              <!-- videos -->
              <div v-if="contes.typeTale == 'Video'"
                class="bg-white h-full w-full  flex flex-col items-center justify-center ">
                <video id="myVideo" class="h-full w-full" @ended="onended" controls controlsList="nodownload">
                  <source :src="contes.videoUrl" type="video/mp4" />
                </video>
              </div>
              <!-- Audio -->
              <div v-if="contes.typeTale == 'Audio'" class="bg-white shadow-sm  mb-2 h-full rounded-lg">
                <div class="flex">
                  <div>
                    <img class="w-full rounded hidden md:block" src="https://tailwindcss.com/img/card-top.jpg"
                      alt="Album Pic">
                  </div>
                  <div class="w-full p-8">
                    <div class="flex justify-between">
                      <div>
                        <h3 class="text-2xl text-grey-darkest font-medium">{{ contes.title }}</h3>
                        <p class="text-sm text-grey mt-1">Ghost Stories</p>
                      </div>
                      <div class="text-red-lighter">
                        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path
                            d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z" />
                        </svg>
                      </div>
                    </div>
                    <div class="flex justify-between items-center mt-8">
                      <div class="text-grey-darker">
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path
                            d="M6.59 12.83L4.4 15c-.58.58-1.59 1-2.4 1H0v-2h2c.29 0 .8-.2 1-.41l2.17-2.18 1.42 1.42zM16 4V1l4 4-4 4V6h-2c-.29 0-.8.2-1 .41l-2.17 2.18L9.4 7.17 11.6 5c.58-.58 1.59-1 2.41-1h2zm0 10v-3l4 4-4 4v-3h-2c-.82 0-1.83-.42-2.41-1l-8.6-8.59C2.8 6.21 2.3 6 2 6H0V4h2c.82 0 1.83.42 2.41 1l8.6 8.59c.2.2.7.41.99.41h2z" />
                        </svg>
                      </div>
                      <div class="text-grey-darker">
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M4 5h3v10H4V5zm12 0v10l-9-5 9-5z" />
                        </svg>
                      </div>
                      <div class="text-white p-8 rounded-full bg-green-800 shadow-lg">
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z" />
                        </svg>
                      </div>
                      <div class="text-grey-200">
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M13 5h3v10h-3V5zM4 5l9 5-9 5V5z" />
                        </svg>
                      </div>
                      <div class="text-grey-500">
                        <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path
                            d="M5 4a2 2 0 0 0-2 2v6H0l4 4 4-4H5V6h7l2-2H5zm10 4h-3l4-4 4 4h-3v6a2 2 0 0 1-2 2H6l2-2h7V8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mx-4 py-4">
                  <div class="w-full  text-sm text-grey-800">
                    <audio class="w-full" controls controlsList="nodownload" @ended="onended" :src="contes.videoUrl">
                      <!-- <source :src="contes.videoUrl" type="audio/mpeg"> -->
                    </audio>
                  </div>

                </div>
              </div>

              <!-- ecris --> 
              <div v-if="contes.typeTale == 'ecrit'" class="bg-red-600 h-full rounded m-4">
              <embed :src="contes.videoUrl" type="application/pdf" width="100%" height="100%" />

              </div>
            </div>

            <div class="h-auto w-full flex items-center justify-around  p-1  ">
              <div class="flex items-center  w-full">
                <img class="h-12 w-12 rounded-full"
                  src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
                  alt />
                <div class="ml-1">
                  <h4 class="text-left font-bold">{{ contes.title }}</h4>
                  <span class="flex items-center">
                    120K
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4 text-blue-800 font-bold">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </span>
                  </span>
                </div>
                <button class="ml-4 py-1 rounded-full border bg-green-800 text-white px-3">Suivre</button>
              </div>
              <div>
                <!-- like and dislike -->
                <div class="flex">
                  <button @click="AddLike(contes._id)" class="bg-black p-1 rounded-l-lg flex text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                      class="w-6 h-6 text-white">
                      <path
                        d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                    </svg>
                    <span class="text-sm">123</span>
                  </button>
                  <div >
                    <svg  @click="likeTest" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
                     
                      class="w-4 h-4  h" :class="heartButtonClass" >
                      <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!-- video descriptio -->
            <div class="h-auto p-2 bg-gray-200 m-4 ">
              <h4 class="text-left p-2 text-black">38 948 vues 28 janv. 2021</h4>
              <p class="text-left ml-2">
                {{ contes.description }}
              </p>
            </div>
           
            <!-- <input type="range" v-model="currentTime" :max="duration" @input="seekTo"> -->
            <!-- <span>{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span> -->
          </div>
          <!-- comment content -->
          <div class=" bg-white md:w-1/3 h-full m-2 flex flex-col p-1 shadow-sm   ">
            <div class="w-full h-12 flex items-center ml-1">
              <h4 class="text-sm mr-2">Commentaires</h4>
              <div class="h-8 w-8 border rounded-lg bg-gray-800 flex items-center justify-center text-white font-bold">
                {{ commenters.length }}
              </div>
            </div>

            <div
              class="flex flex-wrap items-center w-full relative p-1 bg-white items-center m-4 mx-auto border rounded-lg mb-4 overflow-y-auto">
              <input v-model="commente" type="text"
                class="flex-shrink text-sm flex-grow flex-auto leading-normal w-px flex-1 border-0 h-8 px-3 relative self-center font-roboto text-xl outline-none"
                placeholder="Commentez ici !" />

              <svg @click="AddComment" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
              </svg>

            </div>


            <div class=" w-full m-4 mx-auto  overflow-auto h-90  ">
              <div v-for="(comment, i)  in commenters" :key="i" class="flex items-center px-1 pointer -lg m-2">
                <div class="h-8 w-8 ">
                  <img class="h-full w-full rounded"
                    src="https://img.freepik.com/vecteurs-libre/homme-affaires-caractere-avatar-isole_24877-60111.jpg?w=2000"
                    alt />
                </div>
                <div class=" mx-2 ">
                  <h1 class="text-left">Rayna Bengson</h1>
                  <p>{{ comment.message }}</p>
                  <div class="flex">
                    <div class="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4 font-bold text-gray-700">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15 15l-6 6m0 0l-6-6m6 6V9a6 6 0 0112 0v3" />
                      </svg>
                      <span class="text-gray-500 ml-1">Repondre</span>
                    </div>
                    <div class="flex items-center ">
                      <span class="text-gray-500 ml-1">Il ya 2h</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import Video from '../components/video.vue'
import Modal from "../components/Modal.vue";
import NoteModal from "../components/NoteModal.vue"

//import VueConfetti from '../components/ConfettiExplosion.vue'
import VueConfetti from 'vue-confetti'
import axios from 'axios'
export default {
  components: {
    Video,
    Modal,
    NoteModal
    
  },

 data() {
    return {
      isOpen: true,
      modalActive: true,
      test: false,
      showModal: false,
      showModal2: false,
      error:'',

      VueConfetti,
      showVolum: false,
      isPlaying: false,
      contes: [],
      id: null,
      commente: '',
      commenters: [],
      like: null,
      notes: [],
      // questions
      question1: '',
      question2: '',
      question3: '',
      // block question1
      responses1: [],
      response1_1: '',
      response1_2: '',
      response1_3: '',
      // block question2
      responses2: [],
      response2_1: '',
      response2_2: '',
      response2_3: '',
      // block question3
      responses3: [],
      response3_1: '',
      response3_2: '',
      response3_3: '',

      result1: false,
      result2: false,
      result3: false,
      result4: false,
      result5: false,
      result6: false,
      result7: false,
      result8: false,
      result9: false,
      liked:false,

    };
  },
  computed: {
      heartButtonClass(contes) {
        return this.contes.likeCount == 1 ? 'heart' : 'hearted';
      }
    },
  mounted() {
    this.id = this.$route.params.id;
    this.fetchData();
    // this.getNote();
  },

  methods: {

    async fetchData() {
      let response = await axios.get(`/api/v1/admin/tale/${this.id}`);
      console.log(response);
      this.contes = response.data.tale;
      this.commenters = this.contes.comments;
      // questions
      this.question1 = this.contes.questions[0].question;
      this.question2 = this.contes.questions[1].question;
      this.question3 = this.contes.questions[2].question;
      // response1
      this.responses1 = this.contes.questions[0].responses;
      this.response1_1 = this.responses1[0].response;
      this.response1_2 = this.responses1[1].response;
      this.response1_3 = this.responses1[2].response;
      // response2
      this.responses2 = this.contes.questions[1].responses;
      this.response2_1 = this.responses1[1].response;
      this.response2_2 = this.responses1[2].response;
      this.response2_3 = this.responses1[2].response;
      // response3
      this.responses3 = this.contes.questions[2].responses;
      this.response3_1 = this.responses1[1].response;
      this.response3_2 = this.responses1[2].response;
      this.response3_3 = this.responses1[2].response;
      console.log(this.contes);
      console.log('===> response1_1', this.response2_1);


    },
    async AddComment() {
      const data = {
        message: this.commente,
        tale: this.id
      };
      await axios.post('/api/v1/comment/create', data)
        .then((response) => {
          console.log(response.data);
          this.commente = '';
          this.commenters = response.data;
          console.log('commentaires', this.commenters);
        })
        .catch((err) => {
          console.log(err);
        })
    },

    
    async soubmitResponse() {
      const data = {
        taleId: this.id,

        reponse1: [
          { result: this.result1 },
          { result: this.result2 },
          { result: this.result3 },
        ],
        reponse2: [
          { result: this.result4 },
          { result: this.result5 },
          { result: this.result6 },
        ],
        reponse3: [
          { result: this.result7 },
          { result: this.result8 },
          { result: this.result9 },
        ],
      };
      console.log("data", data);
      await axios.post('/api/v1/history-user/register', data)
        .then((response) => {
          console.log(response.data);
          this.notes = response.data.historyStudent.note;
          this.showModal2 = !this.showModal2;
        })
        .catch(err => {
          console.log('erreur',err.data);
        })
    },


    likeTest(){
       this.liked = !this.liked;
       console.log('liked');
    },

    async AddLike(id) {
      await axios.put(`/api/v1/tale-like/${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        })
    },
    onended() {
      this.message()
      this.test = !this.test;
      setTimeout(() => {
        this.test = false;
        this.start()
      }, 500);
    },
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    start() {
      this.$confetti.start();
    },

    stop() {
      this.$confetti.stop();
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.stop();
      this.soubmitResponse();
      this.showModal = !this.showModal;

    },

    message() {
      this.test == false;
      this.showModal = !this.showModal;
      // this.toggleModal();
    }
  }
};
</script>
<style>

.heart {
     width:30px;
      height:30px;
      color:red;
      cursor:pointer;
      animation:scale-up-center .4s cubic-bezier(.39,.575,.565,1.000) both
    }
    hearted{
      width:30px;
      height:30px;
      color:black;
      cursor:pointer;
      animation:scale-up-center .4s cubic-bezier(.39,.575,.565,1.000) both
    }
    .h {
     width:30px;
     height:30px;
    }
  
  
  @keyframes scale-up-center{
    0%{
      transform:scale(.5)}100%{transform:scale(1)}
    }

</style>