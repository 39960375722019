<template>
	<!-- component -->
	<div class="font-mono  h-screen flex items-center justify-center ">
		<!-- Container -->
		<div class="container mx-auto">
			<div class="flex justify-center px-6 my-12">
				<!-- Row -->
				<div class="w-full xl:w-3/4 lg:w-11/12 flex">
					<!-- Col -->
					<div class="w-full h-auto  hidden lg:block lg:w-1/2 bg-cover rounded-l-lg">
						<img src="@/assets/raviv.jpeg" alt="">
					</div>
					<!-- Col -->
					<div class="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
						<div class="px-8 mb-4 text-center">
							<h3 class="pt-4 mb-2 text-2xl">Mot de passe oublié ?</h3>
							<p class="mb-4 text-sm text-gray-700 text-left font-base">
								Veillez entrer votre adresse e-mail pour réinitialiser votre compte.
								Vous recevrez un e-mail pour créer votre nouveau mot de passe.
							</p>
						</div>
						<form class="px-8 pt-6 pb-8 mb-4 bg-white rounded">
							<div class="mb-4">
								<label class="block mb-2 text-sm font-bold text-gray-700" for="email">
									E-mail
								</label>
								<input v-model="emailData.email"
									class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
									id="email" type="email" placeholder="Enter Email Address..." />
							</div>
							<div class="mb-6  ">
								<button ref="button" @click="login()"
									class="flex justify-center w-full px-4 py-2 font-bold text-white bg-red-500 rounded-full hover:bg-red-700 focus:outline-none focus:shadow-outline "
									type="button">
									<span>Réinitialiser votre mot de passe</span>
									<div class=" flex justify-center items-center ml-3">
                <div v-if="loading" class="animate-spin rounded-full h-6 w-6 justify-center border-b-2 border-"></div>
              </div>
								</button>
							</div>
							<hr class="mb-6 border-t" />
							<div class="text-center" >
								<a class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800" href="/login">
									Créer un compte !
								</a>
							</div>
							<div class="text-center">
								<a class="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800" href="login">
									Vous avez déja un compte ? Connectez-vous !
								</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>

import axios from 'axios';
import { inject } from 'vue'

import { ref } from 'vue';
let toogle = ref('diss')
const button = ref('');
let loading = ref(false);
let swal = inject('$swal');

const emailData = ref({
	email: ''
});

async function login() {
	toogle.value = true;
	loading.value = true;
	setTimeout(() => {
		loading.value = !loading.value;
	}, 2000);
	await axios.post('/api/v1/password/forgot', emailData.value)
		.then((data) => {
			console.log(data.data);

			toogle.value = false;
			showAlert();

		}).catch((err)=>{
      if(err){
				invalidCredential();
			}
		})
}
function showAlert() {
	// Use sweetalert2
	swal.fire(
		'Mot de passe réinitialisé avec succès. Vérifiez votre boîte de réception!',
	)
}

function invalidCredential(){
	swal.fire({
  icon: 'error',
  title: 'Oops...',
  text: 'Email invalid!',
})
}




</script>