
<template>
  <div>
    <!-- component -->
    <div v-if="loading">
        <LoadingPage /> 
        </div>
    <div v-else class="p-5 w-full mx-auto h-full bg-green-600">
      <div class="px-4 h-full py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div class="max-w-xl  md:mx-auto sm:text-center lg:max-w-2xl ">
          <div>
          </div>
          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span class="relative inline-block">
              <!-- <svg viewBox="0 0 52 24" fill="currentColor"
                class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                <defs>
                  <pattern id="d0d83814-78b6-480f-9a5f-7f637616b267" x="0" y="0" width=".135" height=".30">
                    <circle cx="1" cy="1" r=".7"></circle>
                  </pattern>
                </defs>
                <rect fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)" width="52" height="24"></rect>
              </svg> -->
              <span class="relative">Étapes</span>
            </span>
            pour devenir conteur
          </h2>

        </div>
        <div class="relative grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-4 sm:grid-cols-2">
          <div class="absolute inset-0 flex items-center justify-center sm:hidden lg:flex">
            <div class="w-px h-full bg-gray-300 lg:w-full lg:h-px"></div>
          </div>
          <div class="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div class="flex items-center justify-between mb-2">
              <p class="text-lg font-bold leading-5">Etape</p>
              <p
                class="flex items-center justify-center w-6 h-6 font-bold rounded text-deep-purple-accent-400 bg-indigo-50">
                1
              </p>
            </div>
            <p class="text-sm text-gray-900">
              Renseigner ses informations personnelles (Identité, E-mail, Téléphone etc).
            </p>
          </div>
       
          <div class="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div class="flex items-center justify-between mb-2">
              <p class="text-lg font-bold leading-5">Etape</p>
              <p
                class="flex items-center justify-center w-6 h-6 font-bold rounded text-deep-purple-accent-400 bg-indigo-50">
                2
              </p>
            </div>
            <p class="text-sm text-gray-900">
              Indiquer sa biographie, ses prestations ou son CV.
            </p>
          </div>
          <div class="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div class="flex items-center justify-between mb-2">
              <p class="text-lg font-bold leading-5">Etape</p>
              <p
                class="flex items-center justify-center w-6 h-6 font-bold rounded text-deep-purple-accent-400 bg-indigo-50">
                3
              </p>
            </div>
            <p class="text-sm text-gray-900">
              Lire et accepter les conditions générales.
            </p>
          </div>
          <div class="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div class="flex items-center justify-between mb-2">
              <p class="text-lg font-bold leading-5">Etape</p>
              <p
                class="flex items-center justify-center w-6 h-6 font-bold rounded text-deep-purple-accent-400 bg-indigo-50">
                4
              </p>
            </div>
            <p class="text-sm text-gray-900">
              Traitement de la demande par RaViv. <br>
              <span class="text-red-500">NB :</span>La durée indicative de traitement de votre demande est de (05) jours ouvrables.
            </p>
          </div>
          <div class="p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2">
            <div class="flex items-center justify-between mb-2">
              <p class="text-lg font-bold leading-5">Etape</p>
              <p
                class="flex items-center justify-center w-6 h-6 font-bold rounded text-deep-purple-accent-400 bg-indigo-50">
                5
              </p>
            </div>
            <p class="text-sm text-gray-900">
              Vous recevrez un mail de confirmation de votre nouveau status de conteur .
            </p>
          </div>
        </div>

      </div>
      <div>

      </div>
      <div class="flex w-full ">
        <button @click="showModal = !showModal" class="bg-green-800 text-white p-2 rounded-lg mx-auto">
          Devenir conteur</button>

      </div>
      

      <modal2 :show="showModal" @close="showModal = false">
        <div class="m-2 w-full">
          <ol class="flex items-center justify-between w-full    mb-4 sm:mb-5">

            <!-- component -->
            <div class="w-full py-6 ">
              <div class="flex justify-center w-full">
                <div class="w-1/4">
                  <div class="relative mb-2">
                    <div class="w-10 h-10 mx-auto bg-green-600 rounded-full text-lg text-white flex items-center">
                      <span class="text-center text-white w-full">
                        <svg class="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                          height="24">
                          <path class="heroicon-ui"
                            d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm14 8V5H5v6h14zm0 2H5v6h14v-6zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div class="text-xs text-center md:text-base">Informations personellles</div>
                </div>

                <div class="w-1/4">
                  <div class="relative mb-2">
                    <div class="absolute flex align-center items-center align-middle content-center"
                      style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                      <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        <div class="w-0 bg-gray-300 py-1 rounded" :class="step >= 2 ? 'bg-green-600' : ''"
                          style="width: 100%;"></div>
                      </div>
                    </div>

                    <div class="w-10 h-10 mx-auto border bg-white rounded-full text-lg text-white flex items-center"
                      :class="step >= 2 ? 'bg-green-600' : ''">
                      <span class="text-center text-white flex items-center justify-center  w-full"
                        :class="step === 2 ? 'texte-white' : ''">
                        
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6  font-bold" :class="step >= 2 ? 'text-white' : 'text-black'">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                        </svg>

                      </span>
                    </div>
                  </div>
                  <div class="text-xs text-center md:text-base">Informations complémentaires</div>
                </div>
                <div class="w-1/4">
                  <div class="relative mb-2">
                    <div class="absolute flex align-center items-center align-middle content-center"
                      style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                      <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                        <div class="w-0 bg-gray-300 py-1 rounded" style="width: 100%;"
                          :class="step === 3 ? 'bg-green-600' : ''"></div>
                      </div>
                    </div>

                    <div
                      class="w-10 h-10 mx-auto  border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                      :class="step === 3 ? 'bg-green-600' : 'bg-white'">
                      <span class="text-center text-gray-600 w-full flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6" :class="step === 3 ? 'text-white' : 'text-black'">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div class="text-xs text-center md:text-base">Conditions générales</div>
                </div>
              </div>
            </div>

          </ol>
          <form @submit.prevent="getData">
            <div v-if="step === 1" class="grid gap-4 mb-4 sm:grid-cols-2">
              <div>
                <label for="" class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom</label>
                <input v-model="conteurData.name" type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Nom">
              </div>
              <div>
                <label for=""
                  class="block text-left  mb-2 text-sm font-medium text-gray-900 dark:text-white">Prénoms</label>
                <input v-model="conteurData.storyTellersName" type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Prénoms">
              </div>
              <div>
                <label for="phone"
                  class="block text-left  mb-2 text-sm font-medium text-gray-900 dark:text-white">Téléphone</label>
                <input v-model="conteurData.phone" type="tel" name="phone" id="password"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="+ XX XX XX XX XX" required="">
              </div>
              <div>
                <label for="country"
                  class="block text-left  mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Pays</label>
                <input v-model="conteurData.fieldOfActivity" type="text" name="country"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Pays" required="">
              </div>
              <div>
                <label class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail</label>
                <input v-model="conteurData.address"  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="raviv@raviv-africa.com">
              </div>
              <div>
                <label class="block text-left mb-2 text-sm font-medium text-gray-900 dark:text-white">Expérience</label>
                <input v-model="conteurData.experience"  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="experiene en tant que conteur">
              </div>
              <div>
                <label for="" class="block text-left  mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Media (Facebook, X, Instagram, TikTok, etc)</label>
                <input  type="text"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="raviv/facebook">
              </div>
            </div>
            <div v-if="step === 2" class=" sm:grid-cols-2">

              <div>
                <label class="text-left block text-semibold mb-2">Biographie, Prestations, CV</label>
                <div @click="openUploadWidget" class="flex items-center justify-center w-full">
                  <label for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-auto border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                        </path>
                      </svg>
                      <p v-if="pathName">{{ pathName }}</p>
                      <p v-else class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">
                          Charger un document</span></p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">docx, pdf</p>
                    </div>
                    <!-- <input id="dropzone-file" type="file" class="hidden" /> -->
                  </label>
                </div>
              </div>


            </div>

            <div v-if="step === 3" class=" sm:grid-cols-2">
              <div class="overscroll-auto ">
                Le symbole du copyright, représenté par le signe ©, est utilisé pour indiquer la protection des droits
                d'auteur sur une œuvre créative. Pour inclure un copyright dans le contexte d'une application web, vous
                pouvez ajouter une mention de copyright dans le pied de page ou dans une section dédiée de votre
                application. Voici un exemple de la façon dont cela pourrait être formulé :

                © [Année de publication] [Nom de votre entreprise ou votre nom]. Tous droits réservés.

                Assurez-vous de remplacer [Année de publication] par l'année de lancement de votre application web et [Nom
                de votre entreprise ou votre nom] par le nom de votre entreprise ou votre propre nom.

                N'oubliez pas que la mention du copyright ne constitue qu'une partie de la protection des droits d'auteur.
                Il est également recommandé de consulter un avocat spécialisé en propriété intellectuelle pour obtenir des
                conseils juridiques précis et adaptés à votre situation particulière.

                  <div class="inline-flex items-center">
    <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="ripple-on"
      data-ripple-dark="true">
      
<div class="flex items-center">
    <input id="link-checkbox" required type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    <label for="link-checkbox" class="mt-px ml-4 font-light text-gray-700 cursor-pointer select-none" htmlFor="ripple-on">
      J'accepte ces Conditions Générales
    </label>
</div>

      <span
        class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
          stroke="currentColor" stroke-width="1">
          <path fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"></path>
        </svg>
      </span>
    </label>
    
  </div>


              </div>
            </div>

          </form>
          <div class="flex justify-between items-center pt-4">
            <div>
              <button v-if="step > 1" @click="previous()"
                type="button"
                class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Précédent
              </button>
              <button @click="closeModale"
              type="button"
                class="text-white ml-2 bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Annuler
              </button>
            </div>
            <button v-if="step <= 2" @click="next()"
              type="button"
              class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Suivant
            </button>
            <button v-if="step === 3" @click="getData"
              type="submit"
              class="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Valider
            </button>
          </div>
        </div>
      </modal2>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import LoadingPage from '../components/LoadingPage.vue';
import { ref  ,onMounted,watch} from 'vue';
import modal2 from '../components/NewConteur.vue';
import {inject} from 'vue'
let loading = ref(true);
let swal = inject('$swal');
let step = ref(1);
let showModal = ref(false);
const pathName = ref('');


const conteurData = ref({
  phone: '',
  address: '',
  fieldOfActivity: '',
  experience:'',
  name:'',
  storyTellersName:'',
  cvUrl:''
});

onMounted(() => {
  showLoading()
  })

function showLoading(){
  loading.value == true;
  setTimeout(() => {
    loading.value = false;
  }, 2000);
}

function showMessage(){
  swal.fire(
  'Merci , votre demande a été envoyé avec success  !',
  'You clicked the button!',
  'success'
)
}

function previous() {
  if (step.value == 1) {
  } else {
    step.value--;
  }
}
function next() {
  if (step.value === 3) {

  } else {
    step.value++;
  }
}






const widget = window.cloudinary.createUploadWidget({
  cloud_name: "dh8xobjon",
  upload_preset: "raviv-db"
}, (error, result) => {
  if (!error && result && result.event === "success") {
    console.log("Done uploading ...", result.info);
   conteurData.value.cvUrl = result.info.url;
    pathName.value = result.info.original_filename;
  }
});

function openUploadWidget() {
  widget.open();
}

// watch(url, (newUrl, oldUrl) => {
//   if (oldUrl) {
//     URL.revokeObjectURL(oldUrl);
//   }
// });



function getData() {
  const requiredFields = ['address', 'name', 'experience', 'fieldOfActivity', 'phone', 'storyTellersName', 'cvUrl'];
  const missingFields = [];

  requiredFields.forEach(field => {
    if (conteurData.value[field] === '') {
      missingFields.push(field);
    }
  });

  if (missingFields.length > 0) {
    const missingFieldsMessage = `Les champs suivants sont requis : ${missingFields.join(', ')}`;
    alert(missingFieldsMessage);
  } else {
    let storytellerData = { ...conteurData.value, phone: Number.parseInt(conteurData.value.phone) };

    // showModal.value = !showModal.value;
    axios
      .post('/api/v1/storyteller/register', storytellerData)
      .then((response) => {
        console.log(response);
        showModal.value = !showModal.value;
        showMessage();
      })
      .catch((err) => console.log(err));
  }
}



function closeModale() {
  showModal.value = !showModal.value;
}

</script>