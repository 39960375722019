import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
// import 'primevue/resources/themes/saga-blue/theme.css'; // Theme CSS (replace 'saga-blue' with your preferred theme)
// import 'primevue/resources/primevue.min.css'; // Base Styles
// import 'primeicons/primeicons.css'; // Icons
import Button from 'primevue/button';
import 'primevue/resources/themes/aura-light-green/theme.css'



// import 'tailwindcss/tailwind.css'
//import '@/assets/css/styles.css'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import {createPinia} from'pinia'
import VueConfetti from 'vue-confetti'


import Swiper from 'swiper';


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';




const app = createApp(App)
app.use(VueTailwindDatepicker)
app.use(VueConfetti)
app.use(createPinia())
app.use(VueSweetalert2)
app.use(store)
app.use(router)
app.use(VueConfetti)
app.use(PrimeVue)
app.mount('#app')


app.component('Button', Button);
// app.directive('ripple', Ripple);
// app.directive('styleclass', StyleClass);
