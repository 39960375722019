<template>
  <main class="h-full w-full">
    <div class="sm:w-2/3 md:max-w-full  mx-auto">
      <span class="text-center text-sm text-gray-400 ml-4 md:ml-0 font-bold text-xl ">Conteurs</span>
    </div>

    <div v-if="!loader" class="flex   items-center md:max-w-full sm:w-2/3 mx-auto items-center justify-between m-4">
      <button v-if="conteurs.length > 1" id="left-button" @click="swipeLeft">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
          <g clip-path="url(#clip0_9_2121)">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.25-7.25a.75.75 0 000-1.5H8.66l2.1-1.95a.75.75 0 10-1.02-1.1l-3.5 3.25a.75.75 0 000 1.1l3.5 3.25a.75.75 0 001.02-1.1l-2.1-1.95h4.59z"
              clip-rule="evenodd" />
          </g>
          <defs>
            <clipPath id="clip0_9_2121">
              <path d="M0 0h20v20H0z" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <div class="center  " id="content " ref="content">
        <div v-for="(conteur, i) in conteurs" :key="i" class="internal p-1 rounded-lg w-auto hover:shadow-2xl  h-12">
          <router-link :to="{ name: 'ConteurId', params: { id: conteur._id } }"
            class="bg-black text-white w-48 h-full rounded-full py-1 px-2 "> {{ conteur.storyTellersName
            }}</router-link>
        </div>
      </div>

      <button v-if="conteurs.length > 1" id="right-button" @click="swipeRight">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-10 h-10">
          <path fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.75 9.25a.75.75 0 000 1.5h4.59l-2.1 1.95a.75.75 0 001.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 10-1.02 1.1l2.1 1.95H6.75z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <!-- skeleton scroll lefft to right -->
    <div v-if="loader" class="flex mt-6  items-center md:max-w-full sm:w-2/3 mx-auto items-center justify-between m-6">
      <button id="left-button" @click="swipeLeft">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          class="w-10 h-10 text-gray-200 animate-pulse">
          <g clip-path="url(#clip0_9_2121)">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.25-7.25a.75.75 0 000-1.5H8.66l2.1-1.95a.75.75 0 10-1.02-1.1l-3.5 3.25a.75.75 0 000 1.1l3.5 3.25a.75.75 0 001.02-1.1l-2.1-1.95h4.59z"
              clip-rule="evenodd" />
          </g>
          <defs>
            <clipPath id="clip0_9_2121">
              <path d="M0 0h20v20H0z" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <div class="center  " id="content " ref="content">
        <div v-for="author in contes" class="internal p-1 rounded-lg w-auto hover:shadow-2xl  h-12">
          <router-link to="/conteur/1222">
            <button class="bg-gray-200 px-8 h-8 rounded-full animate-pulse  ">{{ contes.title }} </button>
          </router-link>
        </div>
      </div>

      <button class="" id="right-button" @click="swipeRight">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          class="w-10 h-10 text-gray-200 animate-pulse">
          <path fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.75 9.25a.75.75 0 000 1.5h4.59l-2.1 1.95a.75.75 0 001.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 10-1.02 1.1l2.1 1.95H6.75z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>


    <!-- history -->
    <div>
      <div class="max-w-full md:w-2/3   mx-auto p-4 bg-gray-50 rounded ">
        <h1 class="ml-1 text-gray-400 text-sm font-bold ">Historique des contes</h1>
        <carousel :items-to-show="1.5" :itemsToScroll="1" :touchDrag="true">
          <slide v-for="(note, i) in notes" :key="note">
            <!-- <div   class="flex items-center justify-center max-w-full bg-white shadow-lg rounded-lg h-36 mr-3 border border-4 m-2    ">
          <div class=" w-full  h-full">
            <img class=" h-full  w-full"  
              src="https://www.guineenondi.com/wp-content/uploads/2019/08/Conte-africain-P9-678x381.jpg">
          </div>
          <div class="w-full  overflow-auto  h-full flex flex-col items-center justify-center  p-3 ">
            <div class="flex items-center justify-between  w-full p-3">
              <h1 class="text-gray-900 font-bold text-sm"> {{ note.tale.title }} </h1>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </div>
            <div class="md:flex items-center justify-between  w-full">
              <p class="  text-gray-600 text-sm">{{note.createdAt}}</p>
            <p class="border md:p-1 rounded">Note: <span class="text-red-800 font-bold">{{note.note}}/10</span></p>
            </div>
          
            <div class="flex item-center justify-between  w-full   ">
              <h5 class="text-gray-700 text-sm"> {{ note.percentage }} </h5>
              <button class=" py-3 px-2 cursor-pointer bg-gray-800 text-white text-xs font-bold uppercase rounded">Revoir ce
                conte</button>
            </div>
          </div>
        </div> -->
            <div
              class="md:max-w-sm  w-full h-auto flex flex-col justify-between dark:bg-gray-800 bg-white dark:border-gray-700 rounded-lg border border-gray-400 mb-6 py-5 px-4 m-2">
              <div>
                <router-link :to="{ name: 'Detail', params: { id: note.tale._id } }" >
                  <h4 class="text-gray-800 dark:text-gray-100 font-bold mb-2">{{ note.tale.title }}</h4>

                  </router-link>
                <p class="text-gray-800 text-xl  dark:text-gray-100 text-sm">Note: <pan class="text-red-500 font-bold">{{
                  note.note }}/<span>10</span></pan>
                </p>
                <!-- progress bar -->
                <div class="p-1 w-full flex items-center justify-center">
                  <div class="shell">
                    <div class="bart" :style="{ width: note.percentage + '%' }">
                      <span class="progress"> {{ note.percentage }}%</span>
                    </div>
                  </div>
                </div>

                <!-- <div class="mt-3 w-full">
                  <div class="w-full  mx-auto">
                    <div class="bg-gray-200 h-1 flex items-center justify-between">
                      <div class="w-1/3 bg-indigo-700 h-1 flex items-center">
                        <div class="bg-indigo-700 h-6 w-6 rounded-full shadow flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="18"
                            height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M5 12l5 5l10 -10" />
                          </svg>
                        </div>
                      </div>
                      <div class="w-1/3 flex justify-between bg-indigo-700 h-1 items-center relative">
                        <div class="absolute right-0 -mr-2">
                          <div class="relative bg-white shadow-lg px-2 py-1 rounded mt-16 -mr-12">
                            <svg class="absolute top-0 -mt-1 w-full right-0 left-0" width="16px" height="8px"
                              viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Progress-Bars" transform="translate(-322.000000, -198.000000)" fill="#FFFFFF">
                                  <g id="Group-4" transform="translate(310.000000, 198.000000)">
                                    <polygon id="Triangle" points="20 0 28 8 12 8"></polygon>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <p tabindex="5" class="focus:outline-none text-indigo-700 text-xs font-bold">{{
                              note.percentage }}%</p>
                          </div>
                        </div>
                        <div class="bg-indigo-700 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="18"
                            height="18" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M5 12l5 5l10 -10" />
                          </svg>
                        </div>
                        <div class="bg-white h-6 w-6 rounded-full shadow flex items-center justify-center -mr-3 relative">
                          <div class="h-3 w-3 bg-indigo-700 rounded-full"></div>
                        </div>
                      </div>
                      <div class="w-1/3 flex justify-end">
                        <div class="bg-white h-6 w-6 rounded-full shadow"></div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>

              <div v-if="note.percentage < 100" class="mt-4">
                <span>Améliorez votre performance en prenant une <span class="font-bold  text-blue-600">résolution</span>   </span>
              </div>
              <div>
                <div class="flex items-center justify-between text-gray-800 dark:text-gray-100">
                  <p class="text-sm p-2"> Date: {{ dateTime(note.createdAt)}}</p>
                </div>
              </div>
              <router-link v-if="note.percentage < 100" class="border-2  w-full border-gree-600 rounded-lg px-3 py-1 text-green-400 cursor-pointer hover:bg-green-600 hover:text-white" :to="{ name: 'ResolutionPage', params: { id: note.tale._id } } " > Ajouter ma résolution 
                
              </router-link>

            </div>
          </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
    </div>

    <!-- filtrage -->
    <div v-if="!loader" class="max-w-full mx-auto bg-white   md:max-w-4xl mt-4   ">
      <div>
        <fieldset class="border border-solid-4 border-gray-800   rounded-lg p-8 m-4 bg-green-800  ">
          <div class="md:flex ">
            <select v-model="selectedCategory" @change="filterByCategogory"
              class="border md:rounded-l-lg p-2 md:w-auto w-full ">
              <option value="" disabled selected hidden>Type de contes</option>
              <option>Tous les contes</option>
              <option>Ecrit</option>
              <option>Audio</option>
              <option>Video</option>
            </select>

            <select v-model="language" @change="filterByCategogory" class="border border p-2 md:w-auto w-full ">
              <option value="" disabled selected hidden>Langue</option>
              <option>Français</option>
              <option>Anglais</option>

            </select>
            <div class="relative md:w-full  ">
              <input v-model="search" type="search" id="search-dropdown"
                class="block p-3 w-full z-20 text-sm border-2 text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-none border border-gray-300 focus:ring-gray-500 focus:border-none dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-gray-500"
                placeholder="Rechercher un conte, video, Audio text..." required>
              <button @click="filterByCategogory"
                class="absolute top-0 right-0 p-3 text-sm font-medium text-white bg-green-700 rounded-r-lg border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
                <span class="sr-only">Search</span>
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>




    <!-- <video  src="https://drive.google.com/uc?export=download&id=1BL3tigS5ikyKSvqjH3biy9HLlf34F9Gk"  controls></video> -->
    <!-- contes -->
    <div class="bg-white">
      <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div v-if="contes.length > 1">
          <h2 v-if="selectedCategory" class="text-2xl font-bold tracking-tight text-gray-900">{{selectedCategory}}</h2>
          <h2 v-else="selectedCategory" class="text-2xl font-bold tracking-tight text-gray-900">Tous les contes</h2>

        </div>
        <div v-if="contes == ''">
          <h2 class="text-2xl font-bold tracking-tight text-gray-900">Aucun conte trouvé</h2>
          <button @click="this.$store.dispatch('loadContes')" class="bg-red-500 px-2 py-1 rounded"> Remettre le filtre à
            zéro </button>
        </div>


        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          <div v-for="(conte, i) in contes" :key="i" class="group relative">
            <article v-if="!loader" class="overflow-hidden rounded-lg shadow-lg m-4">
              <router-link :to="{ name: 'Detail', params: { id: conte._id } }">
                <div class="bg-cover h-64 w-auto">
                  <input class="h-full w-full" type="image"
                    :src="conte.imageBackground" 
                    alt="">
                </div>
              </router-link>
              <header class=" leading-tight  pt-4 pl-4 pr-4">
                <div class="flex items-center justify-between">
                  <h4 class=" text-gray-500  text-sm">{{ conte.title }}</h4>
                  <p class="text-gray-600 text-sm">il ya 12 jours</p>
                </div>
                <div class="flex items-center justify-between">
                  <h4 class=" text-gray-500 text-bold  text-sm">{{ conte.language }}</h4>
                  <p class="text-gray-600 text-sm">{{ conte.typeTale }}</p>
                </div>

              </header>
              <footer class="flex items-center justify-between leading-none p-1 p-4">
                <router-link :to="{ name: 'ConteurId', params: { id: conte.storyteller._id }}"   class="flex items-center no-underline hover:underline text-black" href="#">
                  <img alt="Placeholder" class="block h-8 w-8 text-center rounded-full"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4GKbaMrPiXC1i6d7u8WQgiO9J5bJy7cyQPw&usqp=CAU">
                  <div>
                    <p class="ml-2 text-sm">{{ conte.storyteller.storyTellersName }}</p>

                  </div>
                </router-link>
                <div class=" flex items-center  text-gray-300 hover:text-red-dark">
                  <span class="text-gray-800 text-sm">12K</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                    class="w-5 h-5 text-green-500 cursor-pointer hover:p-1">
                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                    <path fill-rule="evenodd"
                      d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </footer>
            </article>

            <article v-if="loader" class="overflow-hidden rounded-lg shadow-lg m-4 animate-pulse">
              <router-link :to="'/detail?film_id='">
                <div class="bg-cover bg-gray-300 h-64 w-auto">

                </div>
              </router-link>
              <header class=" leading-tight  p-4">
                <div class=" h-10 w-full flex justify-between">
                  <div class="h-8 w-1/3 bg-gray-300"> </div>
                  <div class="h-8 w-1/3 bg-gray-300"> </div>
                </div>


              </header>
              <footer class="flex  w-full items-center justify-between leading-none p-1 p-4">
                <router-link to="/conteur/1222" class="flex items-center no-underline hover:underline text-black"
                  href="#">
                  <div alt="Placeholder" class="block h-8 w-8 bg-gray-300 text-center rounded-full"></div>
                  <div class="h-6 bg-gray-300 w-24 ml-2 "> </div>
                </router-link>
                <div class=" flex items-center  text-gray-300 hover:text-red-dark">
                  <div class="h-6 bg-gray-300 w-16 ">12K</div>
                </div>
              </footer>
            </article>
          </div>
        </div>
        <!-- pagination -->
        <div v-if="!loader && contes.length > 0" class="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">

       <!-- component -->

        </div>
        <div class="flex">
    <button @click="prevPage" class="border border-green-500 text-green-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center hover:bg-green-500 hover:text-white">
        <svg class="h-5 w-5 mr-2 fill-current" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-49 141 512 512" style="enable-background:new -49 141 512 512;" xml:space="preserve">
            <path id="XMLID_10_" d="M438,372H36.355l72.822-72.822c9.763-9.763,9.763-25.592,0-35.355c-9.763-9.764-25.593-9.762-35.355,0 l-115.5,115.5C-46.366,384.01-49,390.369-49,397s2.634,12.989,7.322,17.678l115.5,115.5c9.763,9.762,25.593,9.763,35.355,0 c9.763-9.763,9.763-25.592,0-35.355L36.355,422H438c13.808,0,25-11.193,25-25S451.808,372,438,372z"></path>
        </svg>
        Précédents
    </button>
    <button v-if="contes.length >= 8" @click="nextPage" class="border border-green-500 bg-green-500 text-white block rounded-sm font-bold py-4 px-6 ml-2 flex items-center">
        Suivants
        <svg class="h-5 w-5 ml-2 fill-current" clasversion="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="-49 141 512 512" style="enable-background:new -49 141 512 512;" xml:space="preserve">
        <path id="XMLID_11_" d="M-24,422h401.645l-72.822,72.822c-9.763,9.763-9.763,25.592,0,35.355c9.763,9.764,25.593,9.762,35.355,0
            l115.5-115.5C460.366,409.989,463,403.63,463,397s-2.634-12.989-7.322-17.678l-115.5-115.5c-9.763-9.762-25.593-9.763-35.355,0
            c-9.763,9.763-9.763,25.592,0,35.355l72.822,72.822H-24c-13.808,0-25,11.193-25,25S-37.808,422-24,422z"/>
        </svg>
    </button>

    </div>
  </div>
  </div>

  </main>
</template>

<script>
//import mongoose from "mongoose";
import axios from 'axios';
import Feeds from '../components/Feeds.vue';
import swiper from '../components/swiper.vue'
import { mapGetters, mapActions ,mapState } from 'vuex';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
// import ResolutionModal from "../components/ResolutionModal.vue";
import moment from 'moment'
// import { mapState, mapActions } from 'vuex';


export default {
  components: {
    Feeds,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    // ResolutionModal
   
  },
  data() {
    return {
      showModal:false,
    
      search: '',
      loader: true,
      typeTale: '',
      date: '',
      language: '',
      title: '',
      filterDatas: [],
      selectedCategory: '',
      notes: [],
      resolution: '',


      
    };
  },

  mounted() {
    this.laoding();
    this.getNote();
    this.$store.dispatch('loadContes');
    this.$store.dispatch('fetchStorytellers');
  
  },

  computed: {
    // ...mapState('store', {
    //   page: state => state.page,
    // }),
    contes() {
      return this.$store.state.filtreConte;
    },
    conteurs() {
      return this.$store.state.conteurs;
    },


  },

  methods: {

    dateTime(value) {
      return moment(value).format('DD-MMM-YYYY');
    },

   async  addResolution(tale){
      const data = {
        resolution: this.resolution,
	      taleId: tale
      }

      console.log(data);
    },
    prevPage() {
      this.$store.dispatch('prevPage');
    },
    nextPage() {
      this.$store.dispatch('nextPage');
    },
    filterByCategogory() {
      let datasArray = { selectedCategory: this.selectedCategory, language: this.language, title: this.search }
      this.$store.dispatch('filterByCategogory', datasArray);
      console.log(this.selectedCategory);

    },

    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (!((scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress 
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft() {
      const content = this.$refs.content;
      this.scrollTo(content, -300, 800);
    },
    swipeRight() {
      const content = this.$refs.content;
      this.scrollTo(content, 300, 800);
    },

    async filterData() {
      let response = await axios.get(`/api/v1/admin/tales?typeTale=${this.typeTale}`);
      this.contes = response.data.tales;
      //onsole.log(this.filterDatas);
    },


    async getNote() {
      let response = await axios.get('/api/v1/history-user/notes');
      this.notes = response.data.notes;
      console.log(this.notes);
    },

    laoding() {
      this.loader = true;
      setTimeout(() => {
        this.loader = !this.loader;
      }, 2000);
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },




  },
};
</script>


<style>



.shell {
  height: 20px;
  width: 250px;
  border: 1px solid #aaa;
  border-radius: 13px;
  /* padding: 10px; */
 

}

.bart {
  background: linear-gradient(to right, #B993D6, #8CA6DB);
  height: 20px;
  width: 15px;
  border-radius: 9px;
}

.progress {
  float: right;
  padding: 3px 2px;
  color: #fff;
  font-size: 0.7em;
}

/* button {
  margin: 10px;
  border: 1px solid #aaa;
  background: none;
  border-radius: 50%;
  padding: 5px 8px;
  outline: none;
  cursor: pointer;
} */




.wrapper {
  max-height: 120px;
  border: 1px solid rgb(239, 229, 223);
  display: flex;
  overflow-x: auto;
  width: 50%;
  background-color: blueviolet;
  margin: 0 auto;

}

.item {
  width: 50%;
  height: 110px;
  background-color: aqua;
  text-align: center;
  margin-right: 2px;

}

.wrapper::-webkit-scrollbar {
  width: 0px;
}

.left {
  float: left;
  width: 30%;
  height: 100px;
  border: 1px solid black;
}

.internal {


  display: inline-block;
}

.center {
  float: left;
  width: 80%;


  margin: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.right {
  float: right;
  width: 30%;
  height: 100px;
  border: 1px solid black;
}

.story-ring {
  padding: 2px;
}

.story-text {
  font-size: 13px;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: all 2s ease;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 2s ease;
}
</style>