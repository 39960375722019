<template>


<section class="bg-gray-100">
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <!-- alert component -->
<div v-if="showMessage" class="relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 mb-5">
					<div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
						<div class="text-green-500">
							<svg class="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
						</div>
						<div class="text-sm font-medium ml-3">Ajout de resolution .</div>
					</div>
					<div class="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">{{ message }}</div>
					<div @click="showMessage = !showMessage" class="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-4 text-gray-400 hover:text-gray-800 cursor-pointer">
						<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
					</div>
				</div>
    <div class="">
      <!-- <div class="lg:col-span-2 lg:py-12">
        <p class="max-w-xl text-lg">
          Ajouter votre resolution enfin de complèter votre resolution à 100%.
        </p>

        <div class="mt-8">
          <a href="" class="text-2xl font-bold text-pink-600"> 0151 475 4450 </a>

          <address class="mt-2 not-italic">282 Kevin Brook, Imogeneborough, CA 58517</address>
        </div>
      </div> -->

      <div class="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
        <div class="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-16 h-16 text-green-800">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
            </svg>
          </div>
         <div class="lg:col-span-2 lg:py-12">
        <p class=" text-center text-lg upercae">
          Ajouter une resolution enfin de complèter votre progression à 100%.
        </p>

        <!-- <div class="mt-8">
          <a href="" class="text-2xl font-bold text-pink-600"> 0151 475 4450 </a>

          <address class="mt-2 not-italic">282 Kevin Brook, Imogeneborough, CA 58517</address>
        </div> -->
      </div>
        <form @submit.prevent="addResolution" class="space-y-4">

          <div>
            <label class="sr-only" for="message">Message</label>

            <textarea 
              v-model="resolution"
              class="w-full rounded-lg border border-gray-200 p-3 text-sm"
              placeholder="Ecrivez votre résoluton ici"
              rows="8"
              id="message"
            ></textarea>
          </div>
          <div class="flex items-center justify-between">
              
          <div class="mt-4">
            <button
             
              class="inline-block w-full rounded-lg bg-green-800 px-5 py-3 font-medium text-white sm:w-auto"
            >
              Compléter
            </button>
          </div>

          <div class="mt-4">
            <button @click="backToHome"
              type="button"
              class="inline-block w-full rounded-lg bg-gray-500 px-5 py-3 font-medium text-white sm:w-auto"
            >  Sortir
            </button>
          </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</section>
  
</template>


<script>
import axios from 'axios'
  export default{
    data() {
      return {
        id: null,
        resolution:'',
        message:'',
        showMessage:false
      }
    },

    mounted() {
    this.id = this.$route.params.id

    },
    methods:{
      backToHome(){
          this.$router.push('/contes');
      },
    async addResolution(){
        const data = {
            resolution:this.resolution,
            taleId : this.id
        }
        console.log(data);

        await axios.put('/api/v1/history-student/update',data)
        .then((response)=>{
          console.log(response.data);
          this.message = 'Votre resolution a été enregistrer avec succès!'
          this.showMessage = true;
          this.resolution = " "
          setTimeout(() => {
            this.showMessage = !this.showMessage;
            this.$router.push('/contes')
          }, 5000);
        })
        .catch((err)=>{
          console.log(err);
        })
      }
    }
  }
</script>