<template>

   

<body class="bg-gray-100 p-6">

    <div class="max-w-md mx-auto bg-white rounded p-8 shadow-md">
        <h2 class="text-2xl font-semibold mb-6">Modifier les informations de l'utilisateur</h2>
          <!-- component -->
            <div v-if="message" class="relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
				    	<div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
						<div class="text-green-500">
							<svg class="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
						</div>
						<div class="text-sm font-medium ml-3">Modification du profil.</div>
					</div>
					<div class="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">{{message}}</div>
					<div class="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-4 text-gray-400 hover:text-gray-800 cursor-pointer">
						<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
					</div>
				</div>

        <div v-if="erreur" class="flex bg-red-500 max-w-sm mb-4">
      <div  class="w-16 bg-red-500">
          <div class="p-4">
              <svg class="h-8 w-8 text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M437.019 74.981C388.667 26.629 324.38 0 256 0S123.333 26.63 74.981 74.981 0 187.62 0 256s26.629 132.667 74.981 181.019C123.332 485.371 187.62 512 256 512s132.667-26.629 181.019-74.981C485.371 388.667 512 324.38 512 256s-26.629-132.668-74.981-181.019zM256 470.636C137.65 470.636 41.364 374.35 41.364 256S137.65 41.364 256 41.364 470.636 137.65 470.636 256 374.35 470.636 256 470.636z" fill="#FFF"/><path d="M341.22 170.781c-8.077-8.077-21.172-8.077-29.249 0L170.78 311.971c-8.077 8.077-8.077 21.172 0 29.249 4.038 4.039 9.332 6.058 14.625 6.058s10.587-2.019 14.625-6.058l141.19-141.191c8.076-8.076 8.076-21.171 0-29.248z" fill="#FFF"/><path d="M341.22 311.971l-141.191-141.19c-8.076-8.077-21.172-8.077-29.248 0-8.077 8.076-8.077 21.171 0 29.248l141.19 141.191a20.616 20.616 0 0 0 14.625 6.058 20.618 20.618 0 0 0 14.625-6.058c8.075-8.077 8.075-21.172-.001-29.249z" fill="#FFF"/></svg>
          </div>
      </div>
      <div class="w-auto text-black opacity-75 items-center p-4">
          <span class="text-lg font-bold pb-4">
              Erreur!
          </span>
          <p class="leading-tight">
              {{ erreur }}
          </p>
      </div>
  </div>
        <!-- Premier formulaire -->
        <form @submit.prevent="updateProfile">
            <div @click="OpenUploadWidget" class="mb-4 flex items-center justify-center">
                <div v-if="url" class="relative w-20 h-20 overflow-hidden rounded-full border">
                    <img  :src="url" alt="Avatar" class="w-full h-full object-cover rounded-full ">

                   
                </div>
                <div v-else-if="getUserdata.avatar.url != 'profileurl' && !user " class="relative w-20 h-20 overflow-hidden rounded-full border">
                  <img  :src="getUserdata.avatar.url" alt="Avatar" class="w-full h-full object-cover rounded-full ">

              </div>
              <div v-else class="relative w-20 h-20 overflow-hidden rounded-full border">
                <img  src="https://cdn.icon-icons.com/icons2/2643/PNG/512/man_boy_people_avatar_user_person_black_skin_tone_icon_159355.png" alt="Avatar" class="w-full h-full object-cover rounded-full ">

            </div>
            </div>

            <div class="mb-4">
                <label for="username" class="block text-gray-600 text-sm font-medium mb-2">Nom d'utilisateur</label>
                <input v-model="name" type="text" id="username" name="username" class="w-full p-2 border rounded" :placeholder="getUserdata.name">
            </div>

            <div class="mb-4">
                <label for="email" class="block text-gray-600 text-sm font-medium mb-2">Adresse e-mail</label>
                <input  v-model="email" type="email" id="email" name="email" class="w-full p-2 border rounded" :placeholder="getUserdata.email">
            </div>

            <div class="mt-6">
                <button type="submit" class="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Enregistrer les modifications</button>
            </div>
        </form>

        <!-- Séparation visuelle -->
        <hr class="my-8 border-t-2 border-gray-300">
          <h4 class=" text-2xl text-gray-400 mb-4">Modifiez votre mot de passe</h4>
        <!-- Deuxième formulaire -->
        <form @submit.prevent="changePassword()">
            <div class="mb-4">
                <label for="newPassword" class="block text-gray-600 text-sm font-medium mb-2">Ancien mot de passe</label>
                <input v-model="oldPassword" type="password" id="newPassword" name="newPassword" class="w-full p-2 border rounded">
            </div>

            <div class="mb-4">
                <label for="confirmPassword" class="block text-gray-600 text-sm font-medium mb-2">Nouveau mot de passe</label>
                <input v-model="newPassword" type="password" id="confirmPassword" name="confirmPassword" class="w-full p-2 border rounded">
            </div>

            <div class="mb-4">
                <label for="confirmPassword" class="block text-gray-600 text-sm font-medium mb-2">Confirmer le mot de passe</label>
                <input v-model="confirmPassword" type="password" id="confirmPassword" name="confirmPassword" class="w-full p-2 border rounded">
            </div>

            <div class="mt-6">
                <button type="submit" class="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Enregistrer les modifications du mot de passe</button>
            </div>
        </form>
    </div>

</body>

</template>

<script setup>
// import { useAuthStore } from '../store';
// const store = useAuthStore();
import {ref,watch,computed} from 'vue';
import axios from 'axios'

import { useStore } from 'vuex';
const store = useStore()
const message = ref('');
const erreur = ref('');
const name = ref('');
const email = ref('');
const url = ref('');
// const widget = ref(null)
const photoUrl = ref('');
const oldPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');


const getUserdata = computed(() => store.getters.getUserdata);


const  widget = window.cloudinary.createUploadWidget({
  cloud_name:"dmxpzyrnz",upload_preset:"mavoix"},
  (error,result)=>{
    if (!error && result && result.event === "success") {
      console.log("Done uploading ...",result.info);
      url.value =result.info.url;
    //   fileName.value = result.info.original_filename;
    }
  }
  )
  function OpenUploadWidget(){
  widget.open()
};


watch(url, (newUrl, oldUrl) => {
      if (oldUrl) {
        URL.revokeObjectURL(oldUrl);
      }
});




  async  function updateProfile(){
          let data = {
              name:name.value,
              email:email.value,
             
                urlImage: url.value
          };

          console.log(url.value);
         
        
          await axios.put(`/api/v1/me/update`,data)
          .then((response)=>{
            console.log(response.data);
            message.value = response.data
            setTimeout(() => {
            message.value = '';
          }, 2000);
          // store.commit('SET_USER_DATA', response.data);

            
          })
          .catch((err)=>{
            console.log(err);
            erreur.value = err.response.data.message;

          setTimeout(() => {
            erreur.value = '';
          }, 3000);
          })
       

  
}

async  function changePassword(){
          let data = {
              oldPassword:oldPassword.value,
              newPassword:newPassword.value,
              confirmPassword: confirmPassword.value
          };
          console.log(data);
         
        try {
          const response = await axios.put(`/api/v1/password/update`,data, {
            headers: {
              Accept:"application/json",
            },
          });
          console.log('Image uploaded successfully!', response.data);
          message.value = response.data.message
          setInterval(() => {
            message.value = '';
          }, 2000);
        
          // userId = '';
        } catch (error) {
           erreur.value = error.response.data.message;
         
        }
}

  





</script>