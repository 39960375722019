import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import LoginPage from '../views/LoginPage.vue'
import Contes from '../views/Contes.vue'
import Detail from '../views/Detail.vue'
import CompteEleve from '../views/ParentAcount.vue'
import NewConteur from '../views/NewConteur.vue'
import StudentAccount from '../views/studentAccount.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import ResetPassword from '../views/resetPassword.vue'
import ConteurId from '../views/ConteurId.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import homePage from '../views/Dashboard/homePage.vue'
import StudentData from '../views/Dashboard/StudentData.vue'
import StudentId from '../views/Dashboard/studentId.vue'
import isParent from '../isParent'
import UpdateUserProfile from "../views/UpdateUserProfile.vue";
import ChildDetail from "../views/Dashboard/ChildDetail.vue"
import IntroductPage from "../views/IntroductPage.vue"
import ResolutionPage from '../views/resolutionPage.vue'
const routes = [
  {
    path:'/compte-parent',
    name:'CompteEleve',
    component:CompteEleve
 
  },
  {
    path:'/introduct',
    name : 'IntroductPage',
    component: IntroductPage
  },
  {
    path:'/resolution/:id',
    name : 'ResolutionPage',
    component: ResolutionPage
  },


  {
    // path:'/dashboard',
    // name:'Dashboard',
    // component:Dashboard,
    // meta:{requiresAuth:true},
    // beforeEnter:isParent,
    children:[
      // {
      //   path:'',
      //   name:'homePage',
      //   component:homePage
        
      // },
      // {
      //   path:'/dashboard/studentlist',
      //   name:'StudentData',
      //   component:StudentData,
      //   // meta: {requiresAuth: true}
      // },
      // {
      //   path:'/dashboard/test',
      //   name:StudentId,
      //   component:StudentId,
      //   // meta: {requiresAuth: true}
      // }
    ]

  },

  {
    path:'/conteur/:id',
    name:'ConteurId',
    component:ConteurId,
    meta: {requiresAuth: true},
  },

  {
    path:'/reset/password/:token',
    name:'ResetPassword',
    component:ResetPassword

  },
  {
    path:'/studentAccount',
    name:'StudentAccount',
    component:StudentAccount,
    // meta: {requiresAuth: true}

  },
  {
    path:"/",
    redirect:"/login",
    met:{requireAuth:false}
  },
  {
    path:"/login",
    name:'LoginPage',
    component:LoginPage,
    meta: {requiresAuth: false}

  },


  {
    path:'/newconteur',
    name:'NewConteur',
    component:NewConteur,
    meta: {requiresAuth: true}

  },
  {
    path:'/contes',
    name:'Contes',
    component:Contes,
    meta: {requiresAuth: true}
   

  },
  {
    path:'/detail/:id',
    name:'Detail',
    component:Detail,
    meta: {requiresAuth: true}

  },
  {
    path: '/dashboard/',
    name: 'Dashboard',
    component: Home,
    meta: {requiresAuth: true},
  
    children:[
      {
        path:'/dashboard/home',
        name:'homePage',
        component:homePage,
        meta: {requiresAuth: true}
       
        
      },
      {
        path:'/dashboard/studentlist',
        name:'StudentData',
        component:StudentData,
        meta: {requiresAuth: true}
      },
      {
        path:'/dashboard/studentId/:id',
        name:'StudentId',
        component:StudentId,
        meta: {requiresAuth: true}
      },

      {
        path: 'dashboard/child-detail/:id',
        name: 'ChildDetail',
        component: ChildDetail,
        
      },
    ]
    
  },
  {
    path: '/user-profile',
    name: 'UpdateUserProfile',
    component: UpdateUserProfile,
    meta: {requiresAuth: true}
    
  },
  
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    
  },
  {
    path: '/about',
    name: 'About',
    meta: {requiresAuth: true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const privateRoute =[
  'login'
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})


router.beforeEach((to,from,next)=>{
  const loggedIn = localStorage.getItem('user')
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
  
        next('/')
  }
  next();
  })


  router.beforeEach((to, from, next) => {
    if (privateRoute.includes(to.name)) {
      // redirect to login page with next url
      if (!localStorage.getItem('user')) {
        next('/');
      }  
    }
  
    // redirect to dashboard page if user is already logged in
    if (to.name === '') {
      if (localStorage.getItem('user')) {
        next('/contes');
      }
    }
   
    next();
  });

export default router
