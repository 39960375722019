<template>
  <div class=" h-full  w-full flex items-center justify-center">
    <!-- component -->
    <body class="antialiased font-sans w-full">
    <div class="container mx-auto px-4 sm:px-8">
      <div class="max-w-4xl mx-auto bg-white rounded overflow-hidden  m-6 ">

<!-- Header with Search Bar -->
<div class="px-6 py-4 flex items-center justify-between">
  <h1 class="font-bold text-xl mb-2">Liste des enfants</h1>
  <div class="relative">
    <input type="text" placeholder="Rechercher" class="border-2 border-gray-300 p-2 rounded-md">
    <button class="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500">
      <i class="fas fa-search"></i>
    </button>
  </div>
</div>

  <div v-if="message" class="text-center">
    <span class="text-green-500">{{ message }}</span>
  </div>

<!-- User List -->
<ul class="divide-y divide-gray-300 ">

  <!-- User Item 1 -->

 
  <li v-for="child in getStudents" :key="clild" class="p-2 flex items-center">
  
  
      <img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" alt="User Avatar" class="w-8 h-8 rounded-full mr-4">
    <div>
      <p class="font-semibold"> {{ child.firstname }} {{ child.lastname }} </p>
      <p class="text-gray-500"> {{ child.establishment }} </p>
    </div>
    <div class="ml-auto flex space-x-4">
    
        <router-link :to="{ name: 'StudentId', params: { id: child._id } }">

          <button class="text-blue-500"><i class="fas fa-eye"></i></button>
        </router-link>
     
      <button class="text-green-500"><i class="fas fa-pencil-alt"></i></button>
      <button @click="deleteOneStudent(child._id)" class="text-red-500"><i class="fas fa-trash-alt"></i></button>
    </div>
  </li>
</ul>


</div>
    </div>
</body>
    <teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <modal :show="showModal" @close="showModal = false">
      </modal>
    </teleport>

  </div>
</template>


<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
export default{
  data() {
  return {
    students:[],
    message: ''
  }
},

mounted(){
  
  this.$store.dispatch('fetchStudents');
  this.fetchData();
},
computed:{
    getStudents(){
        return this.$store.state.students;
    }
},



methods:{
 async fetchData(){
  console.log(this.$store.state);
  },
  async deleteOneStudent(id){
  await axios.delete(`/api/v1//parent/student/${id}`)
  .then((Response)=>{
    console.log(Response.data);
    this.message = Response.data.message;
    setTimeout(() => {
      this.message = ''
    }, 3000);
    this.getStudents();
  }).catch((err) =>{
    console.log(err);
  })

}
},

}

</script>






