<template>
  <div class="relative w-full h-auto   border bg-black p-2">

    <div class="relative  bg-white h-full w-full flex flex-col items-center justify-center ">
      <video ref="videoPlayer" class="w-full" @timeupdate="updateTime">
        <source src="../assets/test.mp4" type="video/mp4">
      </video>
      <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center" v-if="!isPlaying">
        <button @click="playVideo" class="text-white bg-blue-500 hover:bg-blue-700 rounded-lg px-4 py-2">
          Play
        </button>
      </div>
    </div>
    <div class="">

      <div class=" w-full ">
        <input type="range" class="slider w-full " :value="currentTime" :max="duration" @input="seekVideo">
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center ">
          <button v-if="!isPlaying" @click="togglePlayPause" class="text-white  rounded-lg px-4 py-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
            </svg>
          </button>
          <button v-else @click="togglePlayPause" class="text-white  rounded-lg px-4 py-2">

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
            </svg>
          </button>
          <button class="" @mouseover="showVolumBar">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6 text-white">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
            </svg>
          </button>
          <div v-if="showVolum" class="ml-4">
            <input type="range" class="slider" :value="volume" min="0" max="1" step="0.01" @input="setVolume">
          </div>
          <div class="ml-4 text-gray-500 text-sm">
            <span class="font-bold">{{ currentTime }}</span>
            <span>/</span>
            <span class="font-bold">{{ duration }}</span>
          </div>

          <svg @click="togglePictureInPicture" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white ml-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
        </div>
        <div class="flex">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoUrl: String
  },
  name:'Video',
  data() {
    return {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      volume: 1,
      controlBar: false,
      showVolum: false

    };
  },
  computed: {
    playPauseIcon() {
      return this.isPlaying ? "far fa-pause-circle" : "far fa-play-circle";
    },
    showControl() {
      return this.controlBar ? "" : "hidden";
    }
  },
  mounted() {
    this.$refs.videoPlayer.addEventListener("canplay", () => {
      this.duration = this.$refs.videoPlayer.duration;
    });
  },
  methods: {

    showVolumBar() {
      this.showVolum = !this.showVolum;
    },
    playVideo() {
      this.$refs.videoPlayer.play();
      this.isPlaying = true;
    },
    togglePlayPause() {
      if (this.isPlaying) {
        this.$refs.videoPlayer.pause();
      } else {
        this.$refs.videoPlayer.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    updateTime() {
      this.currentTime = this.$refs.videoPlayer.currentTime;
    },
    seekVideo(event) {
      this.$refs.videoPlayer.currentTime = event.target.value;
    },
    setVolume(event) {
      this.$refs.videoPlayer.volume = event.target.value;
      this.volume = event.target.value;
      this.isMuted = false;
    },
    async togglePictureInPicture() {
      const videoPlayer = this.$refs.videoPlayer;
      try {
        if (document.pictureInPictureElement === videoPlayer) {
          await document.exitPictureInPicture();
        } else {
          await videoPlayer.requestPictureInPicture();
        }
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>

<style scoped>
.slider input[type=range] {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497E3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #A1D0FF;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #2497E3;
}

input[type=range]:focus::-ms-fill-upper {
  background: #2497E3;
}
</style>