<script >
import axios from 'axios'
export default{
  data() {
    return {
      histories:[],
      finishedTales : []
    }
  },
  computed:{
    getStudents(){
        return this.$store.state.students;
    },
    getUser(){
        return this.$store.state.user;
    }
  },
  methods:{
    async getStudentTaleHistory(){
		let response = await axios.get(`/api/v1//parent/students/historiques`);
		this.histories = response.data;
    console.log(response.data);
      this.finishedTales = response.data.historicalStudents;
      this.finishedTales.filter((el)=>{
        return el.percentage == 100
      });
      console.log(this.finishedTales);
	 },
  },
  mounted() {
    this.getStudentTaleHistory()
  },
}
</script>


<template>
  <div>
    <div class=" h-full">
      <div class="max-w-full mx-auto">
        <div class="bg-white rounded-3xl p-8 mb-5">

          <div class="grid md:grid-cols-2 grid-cols-1  gap-x-20">
            <div>
              <h2 class="text-2xl text-left font-bold mb-4">Nombre d'inscrit.e(s) et évolution</h2>

              <div class="grid grid-cols-2 gap-4">
                <div class="col-span-2">
                  <div class="p-4 bg-green-100 rounded-xl">
                    <div class="font-bold text-xl text-gray-800 leading-none">Bonjour Mme/M. {{ getUser.user.name }}</div>
                    <div class="mt-5">
                      <button type="button" class="inline-flex items-center justify-center py-2 px-3 rounded-xl bg-white text-gray-800 hover:text-green-500 text-sm font-semibold transition">
                        Voir la liste des inscrits.es
                      </button>
                    </div>
                  </div>
                </div>
                <div class="p-4 bg-yellow-100 rounded-xl text-gray-800">
                  <div class="font-bold text-2xl leading-none">{{ getStudents.length }}</div>
                  <div class="mt-2">Nombre d'inscrit.e(s)</div>
                </div>
                <div class="p-4 bg-yellow-100 rounded-xl text-gray-800">
                  <div class="font-bold text-2xl leading-none">{{ histories.historicalStudents?.length }}</div>
                  <div class="mt-2">Nombre de contes suivis</div>
                </div>
                <div class="col-span-2">
                  <div class="p-4 bg-purple-100 rounded-xl text-gray-800">
                    <div class="font-bold text-xl leading-none">Contes et intéractions terminés</div>
                    <div class="mt-2">{{finishedTales.length}} sur {{ histories.historicalStudents?.length }} </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 class="text-2xl font-bold mb-4">Historique de tous les contes lus par les apprenants</h2>
              <!-- histories -->
              <div class="flex-shrink-0 flex-grow overflow-y-auto  h-96">
                <div v-for="(history,index) in histories.historicalStudents " class="space-y-4 m-4" >
                <div class="p-4 bg-white border rounded-xl text-gray-800 space-y-2">
                  <!-- userInfo -->
                  <div class="flex items-center justify-between">
                    <a href="javascript:void(0)" class="font-bold hover:text-yellow-800 hover:underline">{{history.tale.title}}</a>
                    <div class="text-gray-400 text-xs">{{history.createdAt}}</div>
                  </div>
                  <div class="flex justify-between">
                    <div class="text-gray-400 text-lg text-green-800 font-bold border p-1 rounded">Note {{ history.note }} <span class="text-gray-500 "> / 10 </span></div>
                    <h4 class="">Pourcentage : <span class="text-red-500">{{ history.percentage }} %</span> </h4>
                    
                  </div>
                  <div class="text-sm text-gray-600 flex items-center ">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="text-gray-800 inline align-middle mr-1" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                      </svg> -->
                      <div class=" h-7 w-7 rounded-full  flex items-center justify-center  mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                      </div>
                    {{history.user.name}}
                  </div>
                </div>
              </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>