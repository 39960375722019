<template>
  <section class="relative flex flex-col justify-center  md:flex-row h-screen w-screen items-center">
    <div v-if="spinner" class="absolute h-screen w-screen flex items-center justify-center bg-black opacity-25">
      <div class=" border-t-transparent border-solid animate-spin  rounded-lg border-blue-400 border-4 h-12 w-16"></div>
    </div>
    <div class=" hidden lg:block w-full md:w-1/2 xl:w-1/3 h-screen">
      <img src="@/assets/raviv.jpeg" alt="" class="w-full h-full object-cover">
    </div>

    <div class="bg-white md:h-full  w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
          flex items-center justify-center">
      <div class="w-full h-100">
        <div class="w-60 h-60 mx-auto md:hidden block">
          <img src="@/assets/raviv.jpeg" alt="">
        </div>
        <div class="flex w-auto">
          <h1 class=" font-semibold leading-tight">Déjà inscrit.e ? </h1>
          <div>
            <button v-if="!isChild" @click="swithToChild" class="text-green-800 ml-4 text-sm font-semibold">Se connecter comme Elève/Apprenant.e</button>
            <button v-else @click="swithToChild" class="text-green-800 ml-4 text-sm font-semibold">Se connecter comme Parent.e/Enseignant.e</button>

          </div>
        </div>
        <span class="text-red-500">{{ error }}</span>
        <div class="mt-6 ">
          <form  action="">

            <!-- connection avec un compte standart -->
            <div>
              <div v-if="isChild" class="flex w-full items-center justfify-between p-1 mb-2 border rounded-lg">
                <input v-model="name" type="text" placeholder="Entrez votre pseudo"
                  class="w-full p-1 focus:border-0 h-full    focus:placeholder-gray-500 focus:bg-white  focus:border-gray-600 focus:outline-none">
              </div>
              <div class="mb-2 w-full block">
              <!-- <label class="block text-left text-sm mb-1" for="">Email</label> -->
              <div v-if="!isChild" class="flex w-full items-center justfify-between p-1 border rounded-lg">
                <input v-model="email" type="email" placeholder="Entrez votre email"
                  class="w-full p-1 focus:border-0 h-full    focus:placeholder-gray-500 focus:bg-white  focus:border-gray-600 focus:outline-none">
              </div>
            </div>
            <div class="mb-2 w-full block">
              <!-- <label class="block text-left text-sm mb-1" for="">Password</label> -->
              <div class="flex w-full items-center justfify-between p-1 border rounded-lg">
                <input v-model="password" :type="passwordFieldType" placeholder="Entrez votre mot de passe"
                  class="w-full p-1 focus:border-0 h-full     focus:placeholder-gray-500 focus:bg-white  focus:border-gray-600 focus:outline-none">
                <svg v-if="passwordFieldType === 'password' || password.length >0 " @click="showPassword" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  class="w-6 h-6 mr-4 cursor-pointer">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <!-- <svg @click="showPassword" v-if="passwordFieldType === 'text'" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  class="w-6 h-6 cursor-pointer mr-4">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg> -->

              </div>

              <!-- connection de l'enfant -->
              
            </div>
            </div>
            <!-- connection avec un compte enfant -->
         
        
      
            <div class="flex justify-between"><label class="block text-gray-500 font-bold my-4"><input type="checkbox"
                  class="leading-loose text-pink-600"> <span class="py-2 text-sm text-gray-600 leading-snug"> 
                  Se rappeler de moi  </span>
              </label>
              <label class="block text-gray-500 font-bold my-4">
              </label>
              <router-link to="forget-password" class="text-sm text-gray-800">Mot de passe oublié ?</router-link>
            </div>
            <button v-if="!isChild" :disabled="disabled" type="button" @click="login()"
          class=" flex items-center justify-center mt-1 text-lg font-semibold  bg-green-800 w-full text-white rounded-lg px-6 py-2 block shadow-xl hover:text-white hover:bg-green-700">
              Se connecter
              <div class=" flex justify-center items-center ml-3">
                <div v-if="loading" class="animate-spin rounded-full h-6 w-6 justify-center border-b-2 border-"></div>
              </div>
            </button>

            <button v-if="isChild" :disabled="disabled" type="button" @click="loginByChild"
          class=" flex items-center justify-center mt-1 text-lg font-semibold  bg-green-800 w-full text-white rounded-lg px-6 py-2 block shadow-xl hover:text-white hover:bg-green-700">
              Se connecter <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
</svg>

              <div class=" flex justify-center items-center ml-3">
                <div v-if="loading" class="animate-spin rounded-full h-6 w-6 justify-center border-b-2 border-"></div>
              </div>
            </button>

          </form>
       
          <hr class="w-full">

          <div class="mt-4 w-full">
            <button @click="StudenRoute" type="button"
              class="flex justify-start w-full text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class=" mr-2 -ml-1 w-6 h-5 text-green-800 font-bold">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
              </svg>
              Créer un compte "Elève/Apprenant.e"
            </button>

          </div>
          <div class="mt-4 mb-2 w-full">
            <button @click="ShowSpinner" type="button"
              class="flex justify-start w-full text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="mr-2 -ml-1 w-6 h-5 text-green-800">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
              </svg>
              Créer un compte "Parent.e/Enseignant.e"
            </button>
          
          </div>
          
          
        </div>
        <p class="text-sm text-left text-gray-500 mb-8"> <span class="text-black font-bold">NB: </span>Le compte "Parent.e/Enseignant.e" est
          uniquement dédié à tous les parents et éducateurs qui souhaitent suivre la progression de leur enfant/apprenant.e .</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref,onMounted } from 'vue';
import axios from 'axios'
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex'
const route = useRouter();
const store = useStore();

const router = useRouter();
let StudentBool = ref(false);
const register = ref(false);
let passwordFieldType = ref("password");
let spinner = ref(false);
let loading = ref(false);
let disabled= ref(false);
let isChild = ref(false);

// userdata
let name = ref('')
let email = ref('');
let password = ref('');
  
onMounted(() => {
  console.log(isChecked.value);
});
const error = ref('');
const isChecked = ref(false);

function showPassword() {
  passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password";
}

function swithToChild(){
  isChild.value = !isChild.value;
  console.log(isChild.value);
}

function ShowSpinner() {
  spinner.value == false;
  spinner.value = !spinner.value;
  setTimeout(() => {
    route.push('/compte-parent')
  }, 5000);
}

function StudenRoute() {
   spinner.value == false;
  spinner.value = !spinner.value;
  setTimeout(() => {
    route.push('/studentAccount');
    window.scrollTo(0, 0);
  }, 5000);
}

async  function  login() {
  loading.value = true;
      setTimeout(() => {
        loading.value =!loading.value;
        
      }, 2000);

      const data = {
        email:email.value,
        password:password.value
      }

  store.dispatch('login', data, {
    headers: {
      Accept:"application/json",
      "Content-Type": "application/json"
    }
})
    .then(() => {
        route.push('/contes');
        window.scrollTo(0, 0);

      
    })
    .catch((err) => {
      //console.log(err.response.data.message);
      error.value = err.response.data.message;
    })

   



}


async  function  loginByChild() {
  loading.value = true;
      setTimeout(() => {
        loading.value =!loading.value;
        
      }, 2000);

      const data = {
        name:name.value,
        password:password.value
      }

  store.dispatch('loginByChild', data, {
    headers: {
      Accept:"application/json",
      "Content-Type": "application/json"
    }
})
    .then(() => {
        route.push('/contes');
        window.scrollTo(0, 0);

      
    })
    .catch((err) => {
      //console.log(err.response.data.message);
      error.value = err.response.data.message;
    })

   



}


</script>