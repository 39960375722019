<template>
  <div class="    ">
    <nav v-if="loggedIn" class="w-full mb-8 h-12 mb-4 relative  shadow-sm">
      <!-- logo -->
      <div class="w-full flex bg-white  items-center justify-between">
        <router-link to="/contes">
        <div class="flex items-center mr-6">
        
            <img src="@/assets/raviv.jpeg" class="h-16 w-16 flex items-center justify-center" alt="">
            <label for="" class="text-sm cursor-pointer hidden md:block">Racines Vivantes</label>
        </div>
      </router-link>
        <!-- menu -->
        <ul class="hidden  items-center  md:flex  w-2/3 ml-6  ">
          <router-link active-class="active-link" to="/contes"
            class="inline-flex items-center h-full px-2 py-6 -mb-px text-center text-gray-700 bg-transparent border-b-2 border-transparent sm:px-4 -px-1 dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400">
            Contes </router-link>
          <router-link active-class="active-link" to="/newconteur"
            class="inline-flex items-center h-full px-2 py-6 -mb-px text-center text-gray-700 bg-transparent border-b-2 border-transparent sm:px-4 -px-1 dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400">Devenir conteur</router-link>

          <router-link v-if="getUser.user.role === 'parent'" active-class="active-link" to="/dashboard/home"
            class="inline-flex items-center h-full px-2 py-6 -mb-px text-center text-gray-700 bg-transparent border-b-2 border-transparent sm:px-4 -px-1 dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400">Tableau
            de bord</router-link>

          <router-link to="/about" active-class="active-link"
            class="inline-flex items-center h-full px-2 py-6 -mb-px text-center text-gray-700 bg-transparent border-b-2 border-transparent sm:px-4 -px-1 dark:text-white whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400">   
            A propos de RaViv</router-link>



        </ul>
        <!-- search bar -->

        <!-- login ,notif,  -->
        <div class="w-1/3 relative flex items-center justify-end mr-6 flex space-x-3 ">
          <!-- notification -->
          <!-- <button  v-if="userRole.role === 'parent'" class="btn md:hidden">Parent</button>
        <button v-else class="btn2">utilisateur</button> -->
          <div class="relative hidden md:block ">
            <button @click="notif = !notif" class="relative z-10 block rounded-md bg-white p-2 focus:outline-none">
              <svg class="h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
              </svg>
            </button>
            <div v-show="notif" class="  absolute right-0 mt-2 bg-white rounded-md shadow-lg overflow-x-auto z-20"
              style="width:20rem;">
              <div class="py-2">
                <!-- <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                  <img class="h-8 w-8 rounded-full object-cover mx-1"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                    alt="avatar">
                  <p class="text-gray-600 text-sm mx-2">
                    <span class="font-bold" href="#">Sara Salah</span> replied on the <span
                      class="font-bold text-blue-500" href="#">Upload Image</span> artical . 2m
                  </p>
                </a> -->
                <a href="#" class="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                  Aucune notification
                </a>

              </div>
              <!-- <button disabled="true" class="block bg-green-800 text-white text-center font-bold py-2">(0) notifications</button> -->
            </div>
          </div>
          <!-- user avata -->
          <div  v-if="getUserdata !== {}" class="  relative  ">
            <button v-if="getUserdata.avatar.url != 'profileurl' " @click="hoverFunc()" 
              class="relative  z-10 h-10 w-10 block rounded-full border bg-red-100  focus:outline-none flex items-center justify-center">
              <img  class="rounded-full  h-full w-full"
                :src="getUserdata.avatar.url" alt="any">
              <!-- <span   class=" uppercase">{{ getUserdata.name.charAt(0) }}</span> -->
            </button>

            <button v-else  @click="hoverFunc()" 
        class="relative z-10 h-10 w-10 text-white block rounded-full border bg-green-800 focus:outline-none flex items-center justify-center">
  
        <span class="uppercase">{{ getUserdata.name.charAt(0) }}</span>
      </button>


            <div v-show="userToogle" @click="hoverFunc"
              class="absolute right-0 z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 w-64">
              <ul class="py-2 mt-2 text-sm text-gray-700 dark:text-gray-200">
                
                <li>
                  <a href="#"
                    class="block text-sm px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{
                      getUserdata.name }}</a>
                </li>
                <li>
                  <a href="#"
                    class="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{
                      getUserdata.email }}</a>
                </li>
                <li class="block text-semibold px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  <router-link to="/user-profile">Modifier mon profil</router-link>
                </li>
                <router-link @click="logout()" to="/"
                  class="flex items-center  block px-4 py-2 font-bold text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 text-bold mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                  </svg>
                  <pan>Déconnexion</pan>
                </router-link>
              </ul>
            </div>
          </div>
          <button @click="toggleMobilMenu" class="cursor-pointer md:hidden">
            <svg v-if="!mobile" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg  v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 font-bold p-2 border shadow-sm shadow-greeen-100 rounded-full">
              <path fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clip-rule="evenodd" />
            </svg>

          </button>
        </div>
      </div>
      <!-- Mobile navbar  -->
      <div v-if="mobile" class="slide-bottom abolute mx-auto h-auto w-full  md:hidden   bg-white ">
        <ul class="flex flex-col items-center justify-center text-left space-y-4">
          <li @click="goToPage" class="text-left"> <router-link active-class="active-link" to="/contes"
              class=" text-gray-500 font-bold cursor-pointer flex flex-col items-center justify-center">
              Contes </router-link> </li>
          <li @click="goToPage"> <router-link active-class="active-link" to="/newconteur"
              class=" text-gray-500 font-semibold  cursor-pointer flex flex-col items-center justify-center">Devenir-conteur</router-link>
          </li>
          <li v-if="getUser.user.role === 'parent'" @click="goToPage"> <router-link active-class="active-link" to="/dashboard/home"
              class=" text-gray-500 font-bold  cursor-pointer">Tableau de bord</router-link> </li>
          <li @click="goToPage"> <router-link to="/about" active-class="active-link"
              class=" text-gray-500 font-bold cursor-pointer">A propos de RaViv</router-link> </li>

        </ul>
      </div>


    </nav>
    <!-- responsive buttom -->
    <div class="" @click="userToogle = false">
      <router-view> </router-view>
    </div>
    <div>
      <!-- Footer -->
      <div v-if="loggedIn" class="">
        <Footer
          v-if="$route.path != '/dashboard' && $route.path != '/dashboard/home' && $route.path != '/dashboard/studentlist'  && $route.path == 'user-profile'" />
      </div>

    </div>

  </div>
</template>
<script>
import { AuthComputed } from './store/helper'
import Footer from '../src/components/Footer.vue'
import { mapGetters } from 'vuex'
export default {
  components: { Footer },
  computed: {
    ...AuthComputed,
    ...mapGetters(['getUserdata']),
    getUser(){
        return this.$store.state.user;
    }

  },


  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit('SET_USER_DATA', userData)
    }



    // const userdata = JSON.parse(userString);
    // this.userRole= userdata.user;
    // console.log(this.userRole);
  },
  data() {
    return {
      search: '',
      notif: false,
      userToogle: false,
      userRole: [],
      mobile: false
    }
  },
  methods: {
    hoverFunc() {
      this.userToogle = !this.userToogle;

    },
    goToPage() {
      this.mobile = !this.mobile;
      console.log(this.mobile);
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$route.push('/');

      });
    },
    toggleMobilMenu() {
      this.mobile = !this.mobile;
    },

  }
}
</script>
<style scoped>
nav .active-link {
  border-bottom-color: #036420;
}



@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Koulen&family=Lato&family=Nunito&family=Playfair+Display:ital@1&family=Prata&family=Raleway:ital,wght@1,100&family=Roboto&family=Roboto+Condensed&family=Teko&display=swap');

.btn {

  font-family: Roboto, sans-serif;
  font-weight: 800;
  font-size: 14px;
  color: #fff;
  background-color: #0066CC;
  padding: 9px 26px;
  border: 2px solid #0066cc;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  border-radius: 24px 43px 25px 50px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: none;
}

.btn:hover {

  transition: 1000ms;
  padding: 10px 50px;
  transform: translateY(-0px);
  background-color: #fff;
  color: #0066cc;
  border: solid 2px #0066cc;
}

.btn2 {

  font-family: Roboto, sans-serif;
  font-weight: 800;
  font-size: 14px;
  color: #fff;
  background-color: #5fcc00;
  padding: 9px 26px;
  border: 2px solid #43fb4f;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  border-radius: 24px 43px 25px 50px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: none;
}

.btn2:hover {

  transition: 1000ms;
  padding: 10px 50px;
  transform: translateY(-0px);
  background-color: #eaf2e7;
  color: #060000;
  border: solid 2px #37cb09;
}


.slide-right {
  animation: slide-right .5s cubic-bezier(.25, .46, .45, .94)both
}

@keyframes slide-right {
  0% {
    transform: translateX(50)
  }

  100% {
    transform: translateX(100px)
  }
}

.slide-bottom {
  animation: slide-bottom 0.5s cubic-bezier(0.230, 0.460, 0.450, 0.940) ;
}


@keyframes slide-bottom {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(100px)
  }
}
</style>>



