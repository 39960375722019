import { createStore } from 'vuex'
import axios from'axios'
export default createStore({
  namespaced: true,

  state: {
    user :null,
    contes:[],
    filtreConte:[],
    conteurs:[],
    storyTeller:null,
    students :[],
    page : 1


  
  },
  mutations: {
  
    SET_USER_DATA (state, userData) {
      state.user = userData;
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common['Authorization'] = `Bearer ${
        userData.token
      }`
    },

    // SET_CHILD_DATA (state, userData) {
    //   state.user = userData;
    //   localStorage.setItem('user', JSON.stringify(userData))
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${
    //     userData.token
    //   }`
    // },

    CLEAR_DATA_MUTATION(){
      localStorage.removeItem('user');
       location.reload();
    },

    SET_CONTES(state,contes){
      state.contes = contes;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
     SET_CONTES_FILTRE(state,contes){
      state.filtreConte = contes;
    },
    setFilteredData(state, filteredData) {
      state.filtreConte = filteredData;
    },
    SET_STORYTELLERS(state, storytellers) {
      state.conteurs = storytellers;
    },

    ONE_STORY_TELLER(state,tellerData){
      state.storyTeller = tellerData;
    },
    SET_STUDENTS(state,payload){
      state.students = payload;
    }
  
   
   
  },
  actions: {

   async loadContes({commit,state}){
      let response = await axios.get(`/api/v1/admin/tales?page=${state.page}`);
      commit('SET_CONTES',response.data.tales);
      commit('SET_CONTES_FILTRE',response.data.tales);
      console.log('page',state.page);
    },

    nextPage({ commit, dispatch, state }) {
      commit('SET_PAGE', state.page + 1);
      dispatch('loadContes');
    },
    prevPage({ commit, dispatch, state }) {
      if (state.page > 1) {
        commit('SET_PAGE', state.page - 1);
        dispatch('loadContes');
      }
    },

  
     async fetchStorytellers ({commit}){
      let response = await axios.get(`/api/v1/list-storyteller`);
      commit('SET_STORYTELLERS', response.data.users);
      console.log(response.data.users)
  
    },

    async  getStoryTeller({commit,state}, id){
      await axios.get(`/api/v1/storyteller/${id}`)
      .then((response)=>{
        response.data;
        commit('ONE_STORY_TELLER',response.data);
      })
      .catch((err)=> console.log(err))
    },
   

   register ({commit},credentials){
     return axios.post('/api/v1/register', credentials)
     .then(({data})=>{
       commit('SET_USER_DATA',data);
     })
   },

   registerParent ({commit},credentials){
    return axios.post('/api/v1/parent/register', credentials)
    .then(({data}) => {
     commit('SET_USER_DATA',data);
    })

  },
  studentRegister ({commit},credentials){
    return axios.post('/api/v1/register', credentials)
    .then(({data})=>{
      //commit('SET_USER_DATA',data);
    })
  },

   login ({commit},credentials){
    //console.log("ok")
  return axios.post('/api/v1/login',credentials,
    { headers: {
      'Content-Type': 'application/json'
  }})
    .then(({data})=>{
      commit('SET_USER_DATA',data);
    })

  },


  loginByChild ({commit},credentials){
    //console.log("ok")
  return axios.post('/api/v1/login-username',credentials,
    { headers: {
      'Content-Type': 'application/json'
  }})
    .then(({data})=>{
      commit('SET_USER_DATA',data);
    })

  },

    filterByCategogory({ commit, state },filterParams){
      let data = state.contes
      let filteredData = state.contes

      if (filterParams.selectedCategory) {
        if (filterParams.selectedCategory === 'Tous les contes') {
            filteredData = state.contes; // Retourner toute la liste
        } else {
            filteredData = state.contes.filter((item) => item.typeTale === filterParams.selectedCategory);
        }
    }

      // if(filterParams.selectedCategory) {
      //    filteredData = state.contes.filter((item)=>item.typeTale == filterParams.selectedCategory);
      // }

       if(filterParams.language) {
        filteredData = filteredData.filter((item)=>item.language == filterParams.language);
     }

     if(filterParams.title) {
      filteredData = filteredData.filter((item)=> {
        let firstTitleLowerCase = item.title.toLowerCase()
        let secondTitleLowerCase = filterParams.title.toLowerCase()
        let index = firstTitleLowerCase.indexOf(secondTitleLowerCase)
        if (index !== -1) {
          return true;
        } else {
          return false;
        }
      });
   }

      commit('setFilteredData', filteredData);
},

async fetchStudents({commit}){
 let response=  await axios.get('/api/v1/parent/students')
  
    commit('SET_STUDENTS',response.data.students)
},


 
  logout({commit}){
   commit('CLEAR_DATA_MUTATION');
  }

  },
  getters:{
    loggedIn(state){
      return !!state.user
    },

    filteredData (state) {
      return state.contes;
    },
    getPage(state){
      return state.page
    },
    getConteur(state){
      return state.conteurs;
    },

    getTeller(state){
      return state.storyTeller;
    },

    getUserdata(state){
      return state.user.user;
    },
    getStudents(state){
      return state.students;
    },

    getStudentAmout(state){
     return state.students.length;
     }
   
  },
  modules: {
  }
})
