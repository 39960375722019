<template>
  <div class="max-w-full md:w-2/3   mx-auto p-4 bg-gray-50 rounded ">
    <h1 class="ml-1 text-gray-400 text-sm font-bold uppercase">Historiques des contes déja lus</h1>
    <carousel  :items-to-show="1.5"  :itemsToScroll="1" :touchDrag="true">
    <slide v-for="note in notes" :key="note">
      <div   class="flex items-center justify-center max-w-full bg-white shadow-lg rounded-lg h-36 mr-3 border border-4 m-2    ">
          <div class="w-1/3 h-full">
            <img class=" h-full  w-full"  
              src="https://www.guineenondi.com/wp-content/uploads/2019/08/Conte-africain-P9-678x381.jpg">
          </div>
          <div class="w-full  h-full flex flex-col items-center justify-center space-y-3 p-2 ">
            <div class="flex items-center justify-between  mt-2 w-full ">
              <h1 class="text-gray-900 font-bold text-xl">Titre du conte</h1>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </div>
            <div class="flex items-center justify-between  w-full">
              <p class="  text-gray-600 text-sm">suive le 20 Decembre</p>
            <p class="">Note: <span class="text-red-800 font-bold">3/10</span></p>
            </div>
          
            <div class="flex item-center justify-between  w-full   ">
              <h5 class="text-gray-700  text-sm">Nom du conteur</h5>
              <button class=" md:py-2 px-2 cursor-pointer  bg-gray-800 text-white text-xs font-bold uppercase rounded">Revoir ce
                conte</button>
            </div>
          </div>
        </div>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
  </div>
  
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      notes:[],
    }
  },
  methode:{
    async getNote() {
      let response = await axios.get('/api/v1/history-user/notes');
      this.notes =  response.data.notes;
    },
  }
}
</script>
